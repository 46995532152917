import { useEffect, useState } from "react";
import { Flex, Tag, TagLabel } from "../base";
import { fetchWithAuth } from "../../utils";
import { Center, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { SelectedCarPartFull } from "../../models/selected-car-part";
import { FaEuroSign, FaShoppingCart } from "react-icons/fa";
import { GetCarPositionImage } from "./get-car-position-icon";
import { InfoIcon } from "@chakra-ui/icons";

interface Props {
    carId: string
}

export function SelectedParts(props: Props) {
    const [options, setOptions] = useState<SelectedCarPartFull[]>([]);
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        const fetch = async () => {
            const url = `${process.env.REACT_APP_API}/api/carparts/getAllPartsByCarID/${props.carId}`;
            const respone = await fetchWithAuth(url, "", { method: 'GET' })
            const res = await respone.json()
            setOptions(res)

            let uniquePositionsSet: Set<string> = new Set(res.map((carPart: SelectedCarPartFull) => carPart.position));
            let uniquePositionsArray: string[] = Array.from(uniquePositionsSet);
            setCategories(uniquePositionsArray);
        }
        fetch().catch(console.error)

    }, [props.carId])


    return (
        <Flex width="100%" direction="row" wrap="wrap" marginBottom="20px" marginTop="20px">
            <Stack>
                <HStack>
                    <FaShoppingCart />
                    <Text fontSize={20} fontWeight="bold">
                        Dettaglio ricambi
                    </Text>
                </HStack>
                {categories.map(singleCategory =>
                    <Stack >
                        <HStack >
                            <GetCarPositionImage position={singleCategory} />
                            <Text as='b'><Text as='u'><Text fontSize={20} as='cite'>{singleCategory}:</Text></Text></Text>
                        </HStack>
                        <Flex flexWrap="wrap" gap={2}>
                            {options.filter(opt => opt.position === singleCategory).map(opt =>
                                <Stack bg="gray.200" rounded="full" borderRadius="2xl" >
                                    <Tag
                                        size="lg"
                                        borderRadius='full'
                                        variant='solid'
                                        colorScheme='blue'
                                        marginLeft="5px"
                                        marginRight="5px"
                                        marginTop="5px">
                                        <TagLabel>{opt.name}</TagLabel>
                                    </Tag>
                                    <VStack>
                                        <Center hidden={opt.additionalNote === undefined || opt.additionalNote === null || opt.additionalNote === ""}>
                                            <InfoIcon marginRight="5px" />
                                            <label>{opt.additionalNote}</label>
                                        </Center>
                                        <Center>
                                            <FaEuroSign />
                                            <label>{opt.price}</label>
                                        </Center>
                                    </VStack>
                                </Stack>
                            )}
                        </Flex>
                    </Stack>
                )}
            </Stack>
        </Flex>
    );

}