export const IDENTITY_CONFIG = {
  //(string): The URL of the OIDC provider.
  authority: process.env.REACT_APP_AUTHORITY as string,
  //(string): Your client application's identifier as registered with the OIDC provider.
  client_id: "web",
  //The URI of your client application to receive a response from the OIDC provider.
  redirect_uri: process.env.REACT_APP_CLIENT as string,
  //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  loadUserInfo: true,
  filterProtocolClaims: true,
  // (string): The OIDC post-logout redirect URI.
  post_logout_redirect_uri: process.env.REACT_APP_CLIENT as string,
  //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_type: "code",
  response_mode: "query" as "query" | "fragment",
  //(string, default: 'openid'): The scope being requested from the OIDC provider.
  scope: "openid profile PartScout.API",
};
