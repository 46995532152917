import { Flex, ErrorToast, SuccessFullToast } from "../../components";
import { fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react";
import { Feedback } from "../../models";
import { DeleteIcon } from "@chakra-ui/icons";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { $feedbacks } from "../../state/feedbacks";
import { InsertFeedbackPage } from "../../components/feedback";
import { useState } from "react";

interface Props {
    feedbacks: Feedback[]
}

export function FeedbacksSummaryPage(props: Props) {
    const toast = useToast();
    const token = useAccessToken();
    const modalProps = useDisclosure();
    const [feedbackToOpen, setFeedbackToOpen] = useState<Feedback>();
    const cacheInvalidate = useRecoilRefresher_UNSTABLE($feedbacks);

    const deleteFeedback = async (feedback: Feedback) => {
        let url = `${process.env.REACT_APP_API}/api/feedback/deleteFeedback`
        const data = new FormData();
        data.append('payload', JSON.stringify(feedback));
        const res = await fetchWithAuth(url, token, {
            method: 'DELETE',
            body: data
        })

        if (res.status === 200) {
            SuccessFullToast(toast, "Feedback eliminato", "Feedback eliminato con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    return (
        <Flex direction="row" wrap="wrap" gap={3} >
            <TableContainer>
                <Table variant='striped' size="lg">
                    <Thead>
                        <Tr>
                            <Th>Titolo feedback</Th>
                            <Th>Email (se presente)</Th>
                            <Th>Contenuto</Th>
                            <Th>Data</Th>
                            <Th>Elimina</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.feedbacks.map((f: Feedback, index) => {
                            return (
                                <Tr onClick={() => { setFeedbackToOpen(f); modalProps.onOpen() }} cursor="pointer">
                                    <Td maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                        {f.title}
                                    </Td>
                                    <Td>
                                        {f.user}
                                    </Td>
                                    <Td maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                        {f.content}
                                    </Td>
                                    <Td>
                                        {f.createdOn}
                                    </Td>
                                    <Td textAlign="center">
                                        <DeleteIcon cursor="pointer" onClick={(e) => { e.stopPropagation(); deleteFeedback(f) }} />
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <InsertFeedbackPage feedback={feedbackToOpen} isOpen={modalProps.isOpen} onOpen={modalProps.onOpen} onClose={modalProps.onClose} />
        </Flex>
    );
}