import { ReactNode } from "react";
import { Flex, StyledDiv } from "../base";

interface Props {
  children: ReactNode;
}

export const PAGE_WIDTH = 1350;

export function PageContainer(props: Props) {
  return (
    <Flex width="100%" marginTop="10px" marginBottom="10px">
      <StyledDiv
        width="100%"
        maxW={`${PAGE_WIDTH}px`}
        marginLeft="auto"
        marginRight="auto"
        marginBottom="2rem"
        marginTop="4rem"
        paddingLeft="10px"
        paddingRight="10px"
      >
        {props.children}
      </StyledDiv>
    </Flex>
  );
}
