import { Skeleton, Stack } from "..";

interface Props {
  width: number;
}

export function CarGridSkeleton({ width }: Props) {

  return (
    <Stack>
      <Skeleton height="210px" width={width} />
      <Skeleton height="210px" width={width} />
      <Skeleton height="210px" width={width} />
      <Skeleton height="210px" width={width} />
      <Skeleton height="210px" width={width} />
    </Stack>
  );

}