import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "../base";
import { Button, ErrorToast, Input, PageContainer } from "../../components";
import { useState } from "react";
import { AlertDialog, AlertDialogBody, Text, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Center, Stack, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { GUID_EMPTY, fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { SuccessFullToast } from "../../components";
import React from "react";
import { Feedback } from "../../models/feedback";

interface Props {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    feedback?: Feedback;
}

export function InsertFeedbackPage(props: Props) {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null);
    const token = useAccessToken();
    const [feedback, setFeedback] = useState<Feedback>({ content: "", title: "", id: GUID_EMPTY, userId: GUID_EMPTY, user: "" });

    const onSendFeedback = async () => {
        let url = `${process.env.REACT_APP_API}/api/feedback/saveFeedback`
        const data = new FormData();
        const payload: any = { ...feedback };

        data.append('payload', JSON.stringify(payload));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data,
        })

        if (res.status === 200) {
            SuccessFullToast(toast, "Feedback inviato", "Feedback inviato con successo, lo controlleremo al più presto!")
        }
        else {
            ErrorToast(toast, "Feedback NON inviato", "C'è stato un problema nell'invio del feedback, prego riprovare più tardi.")
        }
        const updatedFeedback: Feedback = { ...feedback }
        updatedFeedback.title = "";
        updatedFeedback.content = "";
        setFeedback(updatedFeedback)
        props.onClose();
    }

    const onPropertyChange = async (propName: string, value: any) => {
        const updatedFeedback: any = { ...feedback }
        updatedFeedback[propName] = value
        setFeedback(updatedFeedback)
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><Center>Inviaci un feedback!</Center></ModalHeader>
                <ModalCloseButton />
                <>
                    <PageContainer>
                        <Stack gap={6} padding="30px">
                            <Stack >
                                <Text children="Titolo:" />
                                <Input maxLength={50} placeholder="Inserisci un titolo per il tuo feedback..." size="lg" type="text" isInvalid={feedback.title === ''} value={props.feedback?.title ?? feedback.title} onChange={(e: any) => { onPropertyChange('title', e.target.value) }} />
                            </Stack>
                            <Stack>
                                <Text children="Contenuto:" />
                                <Textarea maxLength={500} placeholder="Scrivi qui cosa vorresti condividere con noi..." size="lg" isInvalid={feedback.content === ''} value={props.feedback?.content ?? feedback.content} onChange={(e: any) => { onPropertyChange('content', e.target.value) }} />
                            </Stack>

                            <Center>

                                <Button
                                    style={{
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        marginBottom: "auto",
                                        marginTop: "auto",
                                    }}
                                    width="200px"
                                    colorScheme="green"
                                    size="lg"
                                    type="submit"
                                    isDisabled={feedback.title === "" || feedback.content === "" || (props.feedback !== undefined && props.feedback !== null)}
                                    onClick={onOpen}
                                >Invia</Button>
                            </Center>
                        </Stack>
                    </PageContainer>

                    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={true}>
                        <AlertDialogOverlay >
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    Conferma invio
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                    Sei sicuro di voler inviare questo feedback?
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button colorScheme='green' onClick={() => { onSendFeedback(); onClose() }} >
                                        Invia
                                    </Button>
                                    <Button ref={cancelRef} onClick={onClose} ml={3}>
                                        Annulla
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </>
            </ModalContent>
        </Modal>
    )
}