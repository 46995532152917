import { useColorModeValue, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLatestCars } from "../../state/cars";
import { Center, Flex, StyledH1, StyledSpan } from "../base";
import { CarCard } from "../car-card";
import { useEffect, useState } from "react";
import { Car } from "../../models";
import { fetchWithAuth } from "../../utils";

export function LatestCars() {
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const bg = useColorModeValue("white", "gray.800");
    const [latestCars, setLatestCars] = useState<Car[]>(useLatestCars(1));
    const [canLoadMoreCars, setCanLoadMoreCars] = useState<boolean>(true);

    const onCardClick = (id: string) => {
        navigate(`/car-detail/${id}`);
    }

    useEffect(() => {
        if (page !== 1) {
            const fetchData = async () => {
                const url = `${process.env.REACT_APP_API}/api/car/latestCars/${page}`;
                const response = await fetchWithAuth(url, "", { method: "GET" });
                const res = await response.json();
                return res as Car[];
            }

            fetchData().then(newCars => {
                if (newCars.length < 3) setCanLoadMoreCars(false);
                setLatestCars(prevCars => [...prevCars, ...newCars]);
            });
        }
    }, [page]);

    const onLoadMore = () => {
        setPage(page + 1);
    }

    return (
        <Center overflow="hidden" bg={bg} mt={5} paddingBottom={{ base: "120px", md: "40px" }}>
            <Flex direction="column" alignItems="center">
                <StyledH1
                    fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    letterSpacing="tight"
                    lineHeight="short"
                    fontWeight="extrabold"
                    color={useColorModeValue("gray.900", "white")}>
                    <StyledSpan display={{ base: "block", xl: "inline" }}>
                        Ultimi annunci inseriti
                    </StyledSpan>
                </StyledH1>
                <Flex mt="5" flexWrap="wrap" gap="2" justifyContent="center" maxWidth="1300px">
                    {latestCars.map(c => <CarCard key={c.id} onClick={onCardClick} car={c} />)}
                </Flex>
                <Button
                    size="lg"
                    type="submit"
                    minWidth="250px"
                    marginBottom="40px"
                    isDisabled={!canLoadMoreCars}
                    onClick={onLoadMore}>
                    Carica altri annunci
                </Button>
            </Flex>
        </Center>
    )
}