import { Flex, ErrorToast, SuccessFullToast } from "../../components";
import { fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { Button, Divider, useToast } from "@chakra-ui/react";


export function FaqAdminPage() {
    const toast = useToast();
    const token = useAccessToken();

    const clearManufacturerAndModelsCache = async () => {
        let url = `${process.env.REACT_APP_API}/api/admin/clearFaqCache`
        const res = await fetchWithAuth(url, token, {
            method: 'PATCH'
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Cache svuotata", "Cache svuotata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
    };

    return (
        <Flex direction="row" wrap="wrap" gap={3}>
            <Button onClick={clearManufacturerAndModelsCache}>Svuota cache FAQs</Button>
            <Divider />
        </Flex>
    );
}