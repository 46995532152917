import { Box, Text } from "@chakra-ui/react"
import { WorkingHours } from "../../models/working-hours"
import { useEffect, useState } from "react"

interface Props {
    workingHours?: WorkingHours[]
}

export function GetOpeningHoursForSummary(props: Props) {
    const [labelToDisplay, setLabelToDisplay] = useState<string>("Chiuso");
    const [nextOpeningLabel, setNextOpeningLabel] = useState<string>("");
    useEffect(() => {
        let morningOpeningMinutes: number = 0
        let morningClosingMinutes: number = 0
        let eveningOpeningMinutes: number = 0
        let eveningClosingMinutes: number = 0
        const now = new Date();
        const day = [7, 1, 2, 3, 4, 5, 6][now.getDay()];
        const currentMinutes = now.getHours() * 60 + now.getMinutes();
        const workingHoursForDay = props.workingHours?.find(w => w.dayNumber === day)
        if (workingHoursForDay) {
            if (!workingHoursForDay.isClosed) {
                if (workingHoursForDay.morningOpeningHour)
                    morningOpeningMinutes = Number.parseInt(workingHoursForDay.morningOpeningHour.substring(0, 2)) * 60 + Number.parseInt(workingHoursForDay.morningOpeningHour.substring(3, 5))
                if (workingHoursForDay.morningClosingHour)
                    morningClosingMinutes = Number.parseInt(workingHoursForDay.morningClosingHour.substring(0, 2)) * 60 + Number.parseInt(workingHoursForDay.morningClosingHour.substring(3, 5))
                if (workingHoursForDay.eveningOpeningHour)
                    eveningOpeningMinutes = Number.parseInt(workingHoursForDay.eveningOpeningHour.substring(0, 2)) * 60 + Number.parseInt(workingHoursForDay.eveningOpeningHour.substring(3, 5))
                if (workingHoursForDay.eveningClosingHour)
                    eveningClosingMinutes = Number.parseInt(workingHoursForDay.eveningClosingHour.substring(0, 2)) * 60 + Number.parseInt(workingHoursForDay.eveningClosingHour.substring(3, 5))

                if (morningOpeningMinutes > 0 && morningClosingMinutes > 0) {
                    if (morningOpeningMinutes < currentMinutes && morningClosingMinutes > currentMinutes) {
                        setLabelToDisplay("Aperto");
                    }
                    else if (morningOpeningMinutes > currentMinutes) {
                        setNextOpeningLabel("Apre oggi alle ore: " + workingHoursForDay.morningOpeningHour)//"Troppo presto per la mattina"
                    }
                    else if (morningClosingMinutes < currentMinutes) {
                        if (eveningOpeningMinutes > 0 && eveningOpeningMinutes > morningClosingMinutes) {
                            setNextOpeningLabel("Apre oggi alle ore: " + workingHoursForDay.eveningOpeningHour)//"Troppo tardi per la mattina"
                        } else {
                            UpdateWithNextDayOpeningHours();
                        }
                    }
                }
                if (eveningOpeningMinutes > 0 && eveningClosingMinutes > 0) {
                    if (eveningOpeningMinutes < currentMinutes && eveningClosingMinutes > currentMinutes) {
                        setLabelToDisplay("Aperto");
                        setNextOpeningLabel("");
                    }
                    else if (eveningClosingMinutes < currentMinutes) {
                        UpdateWithNextDayOpeningHours();//"Troppo tardi per il pomeriggio"
                    }
                }
            }
            else {
                UpdateWithNextDayOpeningHours();
            }
        }

        function UpdateWithNextDayOpeningHours() {
            const now = new Date();
            for (let daysToAddToCheck = 1; daysToAddToCheck < 7; daysToAddToCheck++) {
                let day: number;
                if (now.getDay() + daysToAddToCheck < 7) {
                    day = [7, 1, 2, 3, 4, 5, 6][now.getDay() + daysToAddToCheck];
                } else {
                    day = [7, 1, 2, 3, 4, 5, 6][now.getDay() + daysToAddToCheck - 7];
                }
                const workingHoursForDay = props.workingHours?.find(w => w.dayNumber === day)
                if (workingHoursForDay) {
                    if (!workingHoursForDay.isClosed) {
                        if (workingHoursForDay.morningOpeningHour && workingHoursForDay.morningOpeningHour !== "") {
                            if (daysToAddToCheck === 1) {
                                setNextOpeningLabel("Aprirà domani alle ore " + workingHoursForDay.morningOpeningHour)
                            } else {
                                setNextOpeningLabel(`Aprirà ${GetItalianWorkingDays(day)} alle ore ` + workingHoursForDay.morningOpeningHour)
                            }
                            break;
                        }

                        if (workingHoursForDay.eveningOpeningHour && workingHoursForDay.eveningOpeningHour !== "") {
                            if (daysToAddToCheck === 1) {
                                setNextOpeningLabel("Aprirà domani alle ore " + workingHoursForDay.eveningOpeningHour)
                            } else {
                                setNextOpeningLabel(`Aprirà ${GetItalianWorkingDays(day)} alle ore ` + workingHoursForDay.eveningOpeningHour)
                            }
                            break;
                        }
                    }
                }
            }
        }

        function GetItalianWorkingDays(day: number) {
            switch (day) {
                case 1:
                    return ("Lunedì")
                case 2:
                    return ("Martedì")
                case 3:
                    return ("Mercoledì")
                case 4:
                    return ("Giovedì")
                case 5:
                    return ("Venerdì")
                case 6:
                    return ("Sabato")
                case 7:
                    return ("Domenica")
            }
        }
    }, [props.workingHours])

    if (props.workingHours) {
        return (
            <Box>
                <Text color={labelToDisplay === "Chiuso" ? "red" : "green"}>{labelToDisplay}</Text>
                <Text hidden={nextOpeningLabel === ""}>{nextOpeningLabel}</Text>
            </Box>
        )
    }
    else {
        return (<></>)
    }
}

