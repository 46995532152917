import {
  chakra,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuList as ChakraMenuList,
  MenuItem as ChakraMenuItem,
} from "@chakra-ui/react";

export const Menu = chakra(ChakraMenu);
export const MenuButton = chakra(ChakraMenuButton);
export const MenuList = chakra(ChakraMenuList);
export const MenuItem = chakra(ChakraMenuItem);
