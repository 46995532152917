import { selector } from "recoil";
import { $token } from "../auth";
import { fetchWithAuth } from "../../utils";
import { Feedback } from "../../models";

export const $feedbacks = selector({
    key: 'Feedbacks',
    get: async ({ get }) => {
        const token = get($token);
        const url = `${process.env.REACT_APP_API}/api/feedback/getAllFeedbacks`;
        const response = await fetchWithAuth(url, token, { method: 'GET' });
        const result = await response.json();
        return result as Feedback[]
    }
})