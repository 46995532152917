import { useEffect, useState } from "react";
import {
    Input
} from "../../components";
import { Checkbox, Table, TableContainer } from "@chakra-ui/react";
import { WorkingHours } from "../../models/working-hours";
import { DayOfWeek } from "./day-of-week";

interface Props {
    hoursTableVisible?: boolean,
    workingHours?: WorkingHours[];
}

export function OpeningHoursTable(props: Props) {
    const [workingHours, setWorkingHours] = useState<WorkingHours[]>([])

    useEffect(() => {
        if (props.workingHours) {
            setWorkingHours(props.workingHours);
        }
    }, [props.workingHours]);

    const onPropertyChange = async (propName: string, index: number, value: any) => {
        const updatedWorkingHours: any = [...workingHours]
        updatedWorkingHours[index][propName] = value
        setWorkingHours(updatedWorkingHours)
    }

    if (props.hoursTableVisible === true) {
        return (
            <TableContainer id="HoursTable" marginTop="20px" textAlign={"center"} hidden={!props.hoursTableVisible}>
                <label>Giorni e orari di apertura:</label>
                <Table size="lg">
                    <thead>
                        <tr>
                            <th>Giorno della settimana </th>
                            <th>Chiuso</th>
                            <th>Orario mattina</th>
                            <th>Orario pomeriggio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workingHours.map((workingHour, index) => {
                            return (
                                <tr key={index}>
                                    <DayOfWeek dayOfWeek={workingHour.dayNumber} />
                                    <td><Checkbox size="lg" isChecked={workingHour.isClosed} onChange={(e: any) => { onPropertyChange('isClosed', index, e.target.checked) }} /></td>
                                    <td><Input type="time" width={"120px"} value={workingHour.isClosed ? "" : workingHour.morningOpeningHour} disabled={workingHour.isClosed} onChange={(e: any) => { onPropertyChange('morningOpeningHour', index, e.target.value) }} />-<Input type="time" width={"120px"} value={workingHour.isClosed ? "" : workingHour.morningClosingHour} disabled={workingHour.isClosed} onChange={(e: any) => { onPropertyChange('morningClosingHour', index, e.target.value) }} /></td>
                                    <td><Input type="time" width={"120px"} value={workingHour.isClosed ? "" : workingHour.eveningOpeningHour} disabled={workingHour.isClosed} onChange={(e: any) => { onPropertyChange('eveningOpeningHour', index, e.target.value) }} />-<Input type="time" width={"120px"} value={workingHour.isClosed ? "" : workingHour.eveningClosingHour} disabled={workingHour.isClosed} onChange={(e: any) => { onPropertyChange('eveningClosingHour', index, e.target.value) }} /></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        )
    }
    else {
        return (<div></div>);
    }
}