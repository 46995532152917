import { selectorFamily } from "recoil";
import { Car, CarFull, CarList, Filters } from "../../models";
import { GUID_EMPTY, fetchWithAuth } from "../../utils";
import { $token } from "../auth";

export const $latestCars = selectorFamily({
  key: "LatestCars",
  get:
    (page: number) =>
      async ({ get }) => {
        const token = get($token);
        const url = `${process.env.REACT_APP_API}/api/car/latestCars/${page}`;
        const response = await fetchWithAuth(url, token, { method: "GET" });
        const res = await response.json();
        return res as Car[];
      },
});

export const $carList = selectorFamily({
  key: "CarList",
  get:
    (params) =>
      async ({ get }) => {
        const filters: Filters = JSON.parse((params as any).toString());
        const token = get($token);
        const url = `${process.env.REACT_APP_API}/api/car/byFilter`;
        const vendorId = !filters.vendorId ||
          filters.vendorId === "undefined"
          ? GUID_EMPTY
          : filters.vendorId;
        const manufacturer =
          !filters.selectedManufacturer ||
            filters.selectedManufacturer === "undefined"
            ? GUID_EMPTY
            : filters.selectedManufacturer;
        const model =
          !filters.selectedModel || filters.selectedModel === "undefined"
            ? GUID_EMPTY
            : filters.selectedModel;
        const yearMin =
          !filters.yearMin || filters.yearMin === undefined
            ? null
            : filters.yearMin.toString();
        const yearMax =
          !filters.yearMax || filters.yearMax === undefined
            ? null
            : filters.yearMax.toString();
        const priceMin =
          !filters.priceMin || filters.priceMin === undefined
            ? null
            : filters.priceMin.toString();
        const priceMax =
          !filters.priceMax || filters.priceMax === undefined
            ? null
            : filters.priceMax.toString();
        const carParts = !filters.partsToSearch || filters.partsToSearch === undefined ? null : filters.partsToSearch;
        const code = filters.searchCode === undefined || filters.searchCode === "" || filters.searchCode === null ? "" : filters.searchCode;
        const queryString = new URLSearchParams();
        queryString.append("VendorId", vendorId);
        if (code !== "") {
          queryString.append("SearchCode", code)
        } else {
          queryString.append("SelectedManufacturer", manufacturer)
          queryString.append("SelectedModel", model)
          if (yearMin) queryString.append("YearMin", yearMin)
          if (yearMax) queryString.append("YearMax", yearMax)
          if (priceMin) queryString.append("PriceMin", priceMin)
          if (priceMax) queryString.append("PriceMax", priceMax)
          if (carParts) { carParts.forEach(value => { queryString.append("PartsToSearch", value); }); }
        }
        queryString.append("Page", `${filters.page ?? 1}`)
        const filteredUrl = `${url}?${queryString.toString()}`;
        const response = await fetchWithAuth(filteredUrl, token, {
          method: "GET"
        });
        const res = await response.json();
        return res as CarList;
      },
});

export const $car = selectorFamily({
  key: "Car",
  get:
    (id: string) =>
      async ({ get }) => {
        const token = get($token);
        const url = `${process.env.REACT_APP_API}/api/car/byId/${id}`;
        const response = await fetchWithAuth(url, token, {
          method: "GET",
        });
        const res = await response.json() as CarFull;
        if (res.images.length === 0) res.images.push("NoImage");
        return res as CarFull;
      },
});
