import { Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ErrorToast, SuccessFullToast } from "../toasts";
import { useAccessToken } from "../../state/auth";
import { fetchWithAuth } from "../../utils";
import { $favorites } from "../../state/favorites";
import { useRecoilRefresher_UNSTABLE } from "recoil";

interface Props {
    id: string;
}

export function Favorite(props: Props) {
    const [carIsFavorite, setCarIsFavorite] = useState<boolean>();
    const toast = useToast()
    const token = useAccessToken();
    const cacheInvalidate = useRecoilRefresher_UNSTABLE($favorites);

    useEffect(() => {
        if (token !== undefined) {
            const fetch = async () => {
                const url = `${process.env.REACT_APP_API}/api/favorite/carIsFavoriteByUser?carId=${props.id}`;
                const respone = await fetchWithAuth(url, token, {
                    method: 'GET'
                })
                const res = await respone.json()
                setCarIsFavorite(res);
            }
            fetch().catch(console.error)
        }
    }, [token, props.id])


    const SaveCarAsFavorite = async () => {
        if (!carIsFavorite) {
            const url = `${process.env.REACT_APP_API}/api/favorite/addFavorite?carId=${props.id}`;
            const respone = await fetchWithAuth(url, token, {
                method: 'POST'
            })
            const res = await respone.json()
            if (res === true) {
                setCarIsFavorite(true);
                SuccessFullToast(toast, "Aggiunto ai preferiti", "Annuncio salvato tra i preferiti!")
            } else {
                ErrorToast(toast, "Errore", "C'è stato un errore, riprovare più tardi.")
            }
        }
        else {
            const url = `${process.env.REACT_APP_API}/api/favorite/removeFavorite?carId=${props.id}`;
            const respone = await fetchWithAuth(url, token, {
                method: 'DELETE'
            })
            const res = await respone.json()
            if (res === true) {
                setCarIsFavorite(false);
                SuccessFullToast(toast, "Rimosso dai preferiti", "Annuncio rimosso dai preferiti!")
            } else {
                ErrorToast(toast, "Errore", "C'è stato un errore, riprovare più tardi.")
            }
        }
        cacheInvalidate();
    }

    return (
        <Flex gap={1} onClick={e => SaveCarAsFavorite()} hidden={token === undefined} cursor="pointer">
            <IconButton aria-label="Salva annuncio" icon={carIsFavorite ? <BsBookmarkFill /> : <BsBookmark />} size="xs" />
            <Text as="u">{carIsFavorite ? "Annuncio salvato tra i preferiti " : "Salva annuncio"}</Text>
        </Flex>
    );
}