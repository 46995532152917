import { Component, ErrorInfo, ReactNode } from "react";
import { formatError } from "../../utils";
import { Box, Heading, Text } from "../base";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(formatError(error));
    console.error(formatError(errorInfo));
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box textAlign="center" py={10} px={6}>
          <Heading
            display="inline-block"
            fontWeight="900"
            fontSize="40px"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, teal.400, teal.600)"
            backgroundClip="text">
            Error
          </Heading>
          <Text fontSize="18px" mt={3} mb={2}>
            Something went wrong
          </Text>
          <Text color={'gray.500'} mb={6}>
            The page you're looking for seems to have problems
          </Text>
        </Box>
      )
    }

    return this.props.children;
  }
}