import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation } from "react-router-dom";
import { BingProvider, CookieDialog, ErrorBoundary, Footer, Navbar, ProtectedRoute } from "../components";
import { Home } from "./Home";
import { AdminSection } from "./admin-section";
import { CarDetail } from "./car-detail";
import { CarList } from "./car-list";
import { Favorites } from "./favorites";
import { ListingDetail } from "./listing-detail";
import { Listings } from "./listings";
import { NotFound } from "./not-found";
import { PrivacyPolicy } from "./privacy-policy";
import { VendorPage } from "./vendor-page";

function App() {
    const { pathname, search } = useLocation()

    return (
        <ErrorBoundary key={pathname + search}>
            <Helmet>
                <title>Part Scout</title>
                <meta name="description" content="PartScout a site for all your car rebuild needs" />
                <meta property="og:title" content="Part Scout" />
                <meta property="og:description" content="PartScout a site for all your car rebuild needs" />
                <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
            </Helmet>
            <Navbar />
            <BingProvider>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/car-list" element={<CarList />} />
                    <Route path="/car-detail/:id" element={<CarDetail />} />
                    <Route path="/vendor-page/:id" element={<VendorPage />} />
                    <Route path="/listings" element={
                        <ProtectedRoute>
                            <Listings />
                        </ProtectedRoute>}
                    />
                    <Route path="/favorites" element={
                        <ProtectedRoute>
                            <Favorites />
                        </ProtectedRoute>}
                    />
                    <Route path="/listing-detail/:id" element={
                        <ProtectedRoute>
                            <ListingDetail />
                        </ProtectedRoute>}
                    />
                    <Route path="/admin-section" element={
                        <ProtectedRoute admin>
                            <AdminSection />
                        </ProtectedRoute>}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BingProvider>
            <Footer />
            <CookieDialog />
        </ErrorBoundary>
    );
}

export default App;
