import { ReactElement, useCallback, useEffect, useRef, useState } from "react"

interface Props {
    children?: ReactElement
  }

export function BingProvider(props: Props) {
    const initStarterd = useRef(false)
    const [loaded, setLoaded] = useState(false)

    const bingAfterLoad = useCallback(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        const windowObj = window as any
        windowObj.bingAfterLoad = bingAfterLoad
        if (windowObj.Microsoft && windowObj.Microsoft.Maps) {
            bingAfterLoad()
        } else if (!initStarterd.current) {
            initStarterd.current = true
            const scriptTag = document.createElement('script')
            scriptTag.setAttribute('type', 'text/javascript')
            scriptTag.setAttribute('src', 'https://www.bing.com/api/maps/mapcontrol?key=Ak1_Mwx_rKv7aNznrHDH09Txfu-2sjzjeNBiuAJb-LPUZXI1Dau5O9tYG1lfUBt3&callback=bingAfterLoad')
            scriptTag.async = true
            scriptTag.defer = true
            document.body.appendChild(scriptTag)
        }
    }, [bingAfterLoad])

    if (loaded) {
        return <>{props.children}</>
    } else {
        return <></>
    }
}