import { selector } from "recoil";
import { $token } from "../auth";
import { fetchWithAuth } from "../../utils";
import { CarList } from "../../models";

export const $carsToApprove = selector({
    key: 'CarsToApprove',
    get: async ({ get }) => {
        const token = get($token);
        const url = `${process.env.REACT_APP_API}/approval/GetAllCarsToApprove`;
        const response = await fetchWithAuth(url, token, { method: 'GET' });
        const result = await response.json();
        return result as CarList;
    }
})