import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../state/auth";

interface Props {
    children: JSX.Element
    admin?: boolean
}

export function ProtectedRoute(props: Props) {
    const { pathname, search } = useLocation()
    const navigate = useNavigate();
    const user = useUser();
    const auth = useAuth();
    const isAdmin = user?.userName === 'info@partscout.it';

    const [canContinue, setContinue] = useState<boolean>(false);

    useEffect(() => {
        if (!user) {
            auth.signIn({ redirect_uri: `${process.env.REACT_APP_CLIENT}${pathname}${search && search !== '' ? `?${search}` : ''}` });
        } else if (user && props.admin && !isAdmin) {
            navigate('/')
        } else {
            setContinue(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, props.admin, isAdmin, navigate, auth])

    return (<>{canContinue && props.children}</>)
}