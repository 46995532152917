import { useEffect } from "react";
import { useLocation } from "react-router-dom"

export function NotFound() {
    const location = useLocation();

    useEffect(() => {
        window.location.href = '/auth' + location.pathname + location.search
    }, [location.pathname, location.search])

    return (<></>)
}