import { selector } from "recoil";
import { CarList } from "../../models";
import { fetchWithAuth } from "../../utils";
import { $token } from "../auth";

export const $listings = selector({
  key: "Listings",
  get: async ({ get }) => {
    const token = get($token);
    const url = `${process.env.REACT_APP_API}/api/listing/all`;
    const response = await fetchWithAuth(url, token, { method: "GET" });
    const res = await response.json();
    return res as CarList;
  },
});
