import { Flex, Heading, ErrorToast, SuccessFullToast } from "../../components";
import { GUID_EMPTY, fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { Button, Divider, Input, InputGroup, InputLeftAddon, useToast, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useModelAndManufacturers } from "../../state/model-manufacturers";
import { Select } from "chakra-react-select";
import { CarModel } from "../../models";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { $modelAndManufacturers } from "../../state/model-manufacturers";


export function ModelsAndManufacturersPage() {
    const toast = useToast();
    const token = useAccessToken();
    const manufacturersAndModels = useModelAndManufacturers();
    const [newManufacturer, setNewManufacturer] = useState("");
    const [newModel, setNewModel] = useState("");
    const [updatedManufacturerName, setUpdatedManufacturerName] = useState("");
    const [manufacturerIdForNewModel, setManufacturerIdForNewModel] = useState("");
    const [manufacturerIdToRename, setManufacturerIdToRename] = useState("");
    const [manufacturerIdForModelRename, setManufacturerIdForModelRename] = useState("");
    const [modelIdToRename, setModelIdToRename] = useState("");
    const [updatedModelName, setUpdatedModelName] = useState("");
    const [manufacturerIdToDelete, setManufacturerIdToDelete] = useState("");
    const [manufacturerIdForModelDelete, setManufacturerIdForModelDelete] = useState("");
    const [modelIdToDelete, setModelIdToDelete] = useState("");
    const cacheInvalidate = useRecoilRefresher_UNSTABLE($modelAndManufacturers);

    const clearManufacturerAndModelsCache = async () => {
        let url = `${process.env.REACT_APP_API}/api/admin/clearManufacturerAndModelsCache`
        const res = await fetchWithAuth(url, token, {
            method: 'PATCH'
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Cache svuotata", "Cache svuotata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    };

    const addNewManufacturer = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/addNewManufacturer`
        const data = new FormData();
        data.append('payload', JSON.stringify({ manufacturerName: newManufacturer }));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Marca aggiunta", "Marca aggiunta con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const addNewModel = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/addNewModel`
        const data = new FormData();
        const model: CarModel = { manufacturerId: manufacturerIdForNewModel, manufacturerName: "", modelId: GUID_EMPTY, modelName: newModel }
        data.append('payload', JSON.stringify(model));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Modello aggiunto", "Modello aggiunto con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const editManufacturerName = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/editManufacturerName`
        const data = new FormData();
        const model: CarModel = { manufacturerId: manufacturerIdToRename, manufacturerName: updatedManufacturerName, modelId: GUID_EMPTY, modelName: "" }
        data.append('payload', JSON.stringify(model));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Marca aggiornata", "Marca aggiornata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const editModelName = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/editModelName`
        const data = new FormData();
        const model: CarModel = { manufacturerId: manufacturerIdForModelRename, manufacturerName: "", modelId: modelIdToRename, modelName: updatedModelName }
        data.append('payload', JSON.stringify(model));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Modello aggiornato", "Modello aggiornato con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const deleteManufacturer = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/deleteManufacturer`
        const data = new FormData();
        const model: CarModel = { manufacturerId: manufacturerIdToDelete, manufacturerName: "", modelId: GUID_EMPTY, modelName: "" }
        data.append('payload', JSON.stringify(model));
        const res = await fetchWithAuth(url, token, {
            method: 'DELETE',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Marca eliminata", "Marca eliminata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const deleteModel = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/deleteModel`
        const data = new FormData();
        const model: CarModel = { manufacturerId: manufacturerIdForModelDelete, manufacturerName: "", modelId: modelIdToDelete, modelName: "" }
        data.append('payload', JSON.stringify(model));
        const res = await fetchWithAuth(url, token, {
            method: 'DELETE',
            body: data
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Modello eliminato", "Modello eliminato con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const isManufacturerAlreadyPresent = (nameToCheck: string) => {
        if (nameToCheck !== "") {
            if (Object.keys(manufacturersAndModels)?.filter((key) => (manufacturersAndModels[key][0].manufacturerName.toUpperCase() === nameToCheck.toUpperCase())).length > 0) {
                return true;
            }
        }
        return false;
    }
    const isModelAlreadyPresent = (manufacturerId: string, nameToCheck: string) => {
        if (nameToCheck !== "") {
            if (manufacturersAndModels[manufacturerId].filter((car) => (car.modelName.toUpperCase() === nameToCheck.toUpperCase())).length > 0) {
                return true;
            }
        }
        return false;
    }


    return (
        <Flex direction="row" wrap="wrap" gap={3}>
            <Button onClick={clearManufacturerAndModelsCache}>Svuota cache marche e modelli</Button>
            <Divider />

            <Heading >CREAZIONE NUOVA MARCA:</Heading>
            <InputGroup size="lg">
                <InputLeftAddon children="Nuova marca:" />
                <Input placeholder="Nuova marca" size="lg" type="text" isInvalid={isManufacturerAlreadyPresent(newManufacturer)} value={newManufacturer} onChange={(e: any) => { setNewManufacturer(e.target.value) }} />
            </InputGroup>
            <Button onClick={addNewManufacturer} isDisabled={newManufacturer === "" || isManufacturerAlreadyPresent(newManufacturer)}>Crea nuova marca</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >CREAZIONE NUOVO MODELLO:</Heading>
                <Select size="lg"
                    placeholder="Seleziona marca a cui aggiungere il modello"
                    options={Object.keys(manufacturersAndModels)?.map((key) => ({ label: manufacturersAndModels[key][0].manufacturerName, value: manufacturersAndModels[key][0].manufacturerId }))}
                    onChange={(e: any) => { setManufacturerIdForNewModel(e.value) }}
                />
            </Stack>
            <InputGroup size="lg">
                <InputLeftAddon children="Nuovo modello:" />
                <Input placeholder="Nuovo modello" size="lg" type="text" isInvalid={isModelAlreadyPresent(manufacturerIdForNewModel, newModel)} value={newModel} onChange={(e: any) => { setNewModel(e.target.value) }} />
            </InputGroup>
            <Button onClick={addNewModel} isDisabled={newModel === "" || isModelAlreadyPresent(manufacturerIdForNewModel, newModel)}>Crea nuovo modello</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >MODIFICA MARCA ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona marca a cui cambiare il nome"
                    options={Object.keys(manufacturersAndModels)?.map((key) => ({ label: manufacturersAndModels[key][0].manufacturerName, value: manufacturersAndModels[key][0].manufacturerId }))}
                    onChange={(e: any) => { setManufacturerIdToRename(e.value); setUpdatedManufacturerName(e.label) }}
                />
            </Stack>
            <InputGroup size="lg">
                <InputLeftAddon children="Nuovo nome marca:" />
                <Input placeholder="Nuovo nome marca" size="lg" type="text" isInvalid={isManufacturerAlreadyPresent(updatedManufacturerName)} value={updatedManufacturerName} onChange={(e: any) => { setUpdatedManufacturerName(e.target.value) }} />
            </InputGroup>
            <Button onClick={editManufacturerName} isDisabled={updatedManufacturerName === "" || isManufacturerAlreadyPresent(updatedManufacturerName)}>Aggiorna nome marca</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >MODIFICA MODELLO ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona marca"
                    options={Object.keys(manufacturersAndModels)?.map((key) => ({ label: manufacturersAndModels[key][0].manufacturerName, value: manufacturersAndModels[key][0].manufacturerId }))}
                    onChange={(e: any) => { setManufacturerIdForModelRename(e.value) }}
                />
                <Select size="lg"
                    placeholder="Seleziona modello"
                    options={manufacturersAndModels[manufacturerIdForModelRename]?.map((car) => ({ label: car.modelName, value: car.modelId }))}
                    onChange={(e: any) => { setModelIdToRename(e.value); setUpdatedModelName(e.label) }}
                    noOptionsMessage={(obj) => (obj.inputValue = "Seleziona una marca")}
                />
            </Stack>
            <InputGroup size="lg">
                <InputLeftAddon children="Nuovo nome modello:" />
                <Input placeholder="Nuovo nome modello" size="lg" type="text" isInvalid={isModelAlreadyPresent(manufacturerIdForModelRename, updatedModelName)} value={updatedModelName} onChange={(e: any) => { setUpdatedModelName(e.target.value) }} />
            </InputGroup>
            <Button onClick={editModelName} isDisabled={updatedModelName === "" || isModelAlreadyPresent(manufacturerIdForModelRename, updatedModelName)}>Aggiorna nome modello</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >ELIMINAZIONE MARCA ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona marca da eliminare"
                    options={Object.keys(manufacturersAndModels)?.map((key) => ({ label: manufacturersAndModels[key][0].manufacturerName, value: manufacturersAndModels[key][0].manufacturerId }))}
                    onChange={(e: any) => { setManufacturerIdToDelete(e.value) }}
                />
                <Button onClick={deleteManufacturer} isDisabled={manufacturerIdToDelete === ""} colorScheme="red">Elimina marca</Button>
            </Stack>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >ELIMINAZIONE MODELLO ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona marca"
                    options={Object.keys(manufacturersAndModels)?.map((key) => ({ label: manufacturersAndModels[key][0].manufacturerName, value: manufacturersAndModels[key][0].manufacturerId }))}
                    onChange={(e: any) => { setManufacturerIdForModelDelete(e.value) }}
                />
                <Select size="lg"
                    placeholder="Seleziona modello da eliminare"
                    options={manufacturersAndModels[manufacturerIdForModelDelete]?.map((car) => ({ label: car.modelName, value: car.modelId }))}
                    onChange={(e: any) => { setModelIdToDelete(e.value) }}
                    noOptionsMessage={(obj) => (obj.inputValue = "Seleziona una marca")}
                />
                <Button onClick={deleteModel} isDisabled={modelIdToDelete === ""} colorScheme="red">Elimina modello</Button>
            </Stack>
        </Flex>
    );
}