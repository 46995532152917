const color = {
  red: (value: string) => value,
  gray: (value: string) => value,
  magenta: (value: string) => value,
};

/**
 * Formats an Error into a string suitable for printing.
 *
 * @param err The Error object to format. If the value you pass in isn't error-like, it'll get formatted via `String()`.
 * @param options Options that affect the format of the returned string.
 * @returns A formatted string containing information about the error.
 */
export function formatError(
  err: unknown,
  {
    lineFilter = (line: string) => !/\(node:/.test(line),
  }: {
    /**
     * A function that determines whether a given stack trace line
     * should be included in the formatted output.
     *
     * The default filter omits lines from node's internal functions.
     * To override this behavior, pass `lineFilter: () => true`
     */
    lineFilter?: (line: string) => boolean;
  } = {}
): string {
  let prettyErr = String(err);
  if (
    typeof err === "object" &&
    err != null &&
    // @ts-ignore
    typeof err.name === "string" &&
    // @ts-ignore
    typeof err.message === "string" &&
    // @ts-ignore
    typeof err.stack === "string"
  ) {
    const error = err as Error;
    prettyErr =
      color.red(error.name) +
      ": " +
      error.message.replace(new RegExp(`^${error.name}[: ]*`), "") +
      "\n" +
      (error.stack || "")
        .split("\n")
        .map((line) => line.trim())
        .filter(lineFilter)
        .map((line) => {
          if (line.startsWith(error.name + ": " + error.message)) return null;

          if (line.startsWith("at")) {
            return "  " + color.gray(line);
          }

          return line;
        })
        .filter(Boolean)
        .join("\n");
  }

  if (typeof err === "object" && err != null) {
    const propNames = Object.getOwnPropertyNames(err).filter(
      (name) => name !== "stack" && name !== "message"
    );
    if (propNames.length > 0) {
      const props: any = {};
      propNames.forEach((name) => {
        props[name] = (err as any)[name];
      });

      let propertiesString;
      try {
        propertiesString = JSON.stringify(props);
      } catch (err) {
        propertiesString = [
          "{",
          "  the properties object couldn't be converted to JSON :(",
          "",
          "  the error that happened while stringifying it was:",
          formatError(err)
            .split("\n")
            .map((line) => "  " + line)
            .join("\n"),
          "}",
        ].join("\n");
      }

      prettyErr +=
        color.magenta("\nThe above error also had these properties on it:\n") +
        propertiesString;
    }
  }

  return prettyErr;
}
