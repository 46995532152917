import { useNavigate } from "react-router-dom";
import { CarCard, Flex, PageContainer } from "../../components";
import { useFavorites } from "../../state/favorites";
import { Heading } from "@chakra-ui/react";

export function Favorites() {
    const navigate = useNavigate();
    const carList = useFavorites();

    return (
        <PageContainer>
            <Heading textAlign="center" marginBottom="10px">Annunci preferiti</Heading>
            <Flex direction="row" wrap="wrap" gap={5} justifyContent="center" alignItems="center">
                {carList?.carList.map((c) => (
                    <CarCard key={c.id} onClick={e => navigate(`/car-detail/${c.id}`)} car={c} />
                ))}
            </Flex>
        </PageContainer>
    );
}