import { selector } from "recoil";
import { CarModel } from "../../models";
import { fetchWithAuth, groupBy } from "../../utils";
import { $token } from "../auth";



export const $modelAndManufacturers = selector({
  key: "Manufacturers",
  get: async ({ get }) => {
    const token = get($token);
    const url = `${process.env.REACT_APP_API}/api/manufacturer/allManufacturersAndModels`;
    const response = await fetchWithAuth(url, token, { method: "GET" });
    const result = await response.json();
    const grouped = groupBy(result as CarModel[], 'manufacturerId');
    return grouped;
  },
});
