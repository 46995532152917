import { CarCard, SimpleGrid } from "../../components";
import { Car } from "../../models";

interface Props {
  onCarSelected: (id: string) => void,
  gridWidth: number,
  cars: Car[]
}


export function ApproveGrid(props: Props) {

  const onCarSelected = (id: string) => {
    props.onCarSelected(id);
  }


  return (
    <SimpleGrid
      width={`${props.gridWidth}px`}
      paddingLeft="10px"
      paddingRight="10px"
      columns={[2, null, 3]}
      spacing="10px"
    >
      {props.cars.map((c) => (
        <CarCard key={c.id} onClick={onCarSelected} car={c} />
      ))}
    </SimpleGrid>
  )
}