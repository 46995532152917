import { Flex, Heading, PageContainer, Text } from "../../components";

export function PrivacyPolicy() {
  return (
    <PageContainer>
      <Flex direction="column" wrap="wrap">
        <Heading>Privacy e cookie policy</Heading>
        <Text>
          <br />
          Utilizziamo cookie e altre tecnologie per assicurarti la migliore esperienza possibile durante la navigazione.
          Pertanto, durante la tua interazione con PartScout  collocheremo dei cookie nel tuo dispositivo al solo fine di garantirti
          un funzionamento più efficiente del sito. È sempre possibile modificare le impostazioni del tuo browser in modo da rifiutare
          i cookie, tuttavia ti segnaliamo che la disattivazione dei cookie del nostro sito,
          comporta l’impossibilità di accedervi in quanto gli unici cookie istallati hanno il solo scopo di garantirne il
          funzionamento e rientrano pertanto tra quelli definiti necessari.
        </Text>
        <Text fontSize="3xl"><br />Norme di riferimento</Text>
        <Text>
          In ottemperanza al Provvedimento n. 229&nbsp;
          <a style={{ textDecoration: 'underline' }} href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884">
            http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884
          </a>
          emesso dal Garante per la Protezione dei Dati Personali in data 8 maggio 2014 ed in base a quanto riportato nelle linee guida
          10 giugno 2021&nbsp;
          <a style={{ textDecoration: 'underline' }} href=" https://www.garanteprivacy.it/home/docweb/-/docweb-display/docweb/9677876">
            https://www.garanteprivacy.it/home/docweb/-/docweb-display/docweb/9677876
          </a>,
          PartScout fornisce la seguente informativa sui cookie, sulle loro funzioni e su come eventualmente disabilitarli.
        </Text>
        <Text fontSize="3xl"><br />Cosa sono i cookie</Text>
        <Text>
          I cookie sono piccoli file di testo che i siti visitati dall'utente inviano e registrano sul suo personal computer o
          qualsivoglia ulteriore dispositivo, anche mobile, per essere poi ritrasmessi agli stessi siti alla successiva visita.
          I cookie vengono utilizzati per diverse finalità: ad esempio, per ricordare le azioni e preferenze dell'utente
          (come, ad esempio, i dati di login, la lingua prescelta, le dimensioni dei caratteri, altre impostazioni di visualizzazione,
          ecc.) in modo che non debbano essere indicate nuovamente quando l'utente torni a visitare detto sito o navighi
          da una pagina all'altra di esso;
          ovvero per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni
          riguardanti le attività degli utenti che accedono ad un sito e possono contenere anche un codice identificativo
          unico che consente di tenere traccia della navigazione dell'utente all'interno
          del sito stesso per finalità statistiche o pubblicitarie.
          In generale i cookie possono essere inviati dal sito su cui si naviga,
          ovvero anche da siti diversi (c.d. cookie di "terze parti").
          Nel nostro caso, alcune operazioni non potrebbero essere compiute senza l'uso dei cookie,
          che in certi casi sono quindi tecnicamente necessari per lo stesso funzionamento del sito.
        </Text>
        <Text fontSize="3xl"><br />Tipologie di cookie presenti nel sito e le loro funzioni</Text>
        <Text>
          Esistono vari tipi di cookie, nel nostro caso, a seconda delle loro caratteristiche e funzioni, utilizziamo solo i seguenti:
          <ul style={{ marginLeft: '15px' }}>
            <li>
              <Text fontSize="2xl">Essential Cookies</Text>
              Questi cookie abilitano funzionalità di base come la sicurezza, la verifica dell'identità e la gestione della rete.
              Questi cookie non possono essere disabilitati.
            </li>
            <li>
              <Text fontSize="2xl">Marketing Cookies</Text>
              Questi cookie vengono utilizzati per monitorare l'efficacia della pubblicità per fornire un servizio più pertinente
              e fornire annunci migliori per soddisfare i tuoi interessi.
            </li>
            <li>
              <Text fontSize="2xl">Analytics Cookies</Text>
              Questi cookie ci aiutano a capire come i visitatori interagiscono con il nostro sito Web,
              scoprire errori e fornire una migliore analisi complessiva.
            </li>
          </ul>
          <br />
          Puoi impostare e gestire le tue preferenze relative ai cookie in qualsiasi momento, selezionando quali cookie autorizzare, bloccare o cancellare, in tutto o in parte, attraverso le impostazioni del tuo browser (ovvero, il programma di navigazione da te utilizzato). Ti ricordiamo però che il nostro sito utilizza solo cookie di funzionamento, per cui l’eventuale disattivazione comporta l’impossibilità di consultarlo.
          Qui sotto il percorso da seguire per gestire i cookie dei seguenti browser:
          <ul style={{ marginLeft: '15px' }}>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies">
                Google
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it">
                Mozzilla
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">
                Safari
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">
                Explorer
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing">
                Brave
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://help.opera.com/en/latest/web-preferences/#cookies">
                Opera
              </a>
            </li>
          </ul>
          <br />
          Per maggiori informazioni e dettagli sui vari tipi di cookie, sulle loro modalità di funzionamento e caratteristiche,
          ti invitiamo a visitare i siti - ad accesso libero, indipendenti dal nostro  -&nbsp;
          <a style={{ textDecoration: 'underline' }} href="www.AllAboutCookies.org">www.AllAboutCookies.org</a> &nbsp;
          e &nbsp;
          <a style={{ textDecoration: 'underline' }} href="www.youronlinechoices.com">www.youronlinechoices.com</a>,
          oppure il sito del Garante per la Protezione dei dati personali&nbsp;
          <a style={{ textDecoration: 'underline' }} href="www.garanteprivacy.it">www.garanteprivacy.it</a>&nbsp;
          Ti informiamo che bloccare o cancellare, in tutto o in parte:
          <ul style={{ marginLeft: '15px' }}>
            <li>
              i cookie tecnici, potrebbe comportare l'impossibilità di utilizzare il nostro sito, visionarne i suoi
              contenuti ed usufruire dei relativi servizi;
            </li>
            <li>
              i cookie di funzionalità, potrebbe comportare che alcuni servizi o determinate funzioni del sito
              non siano disponibili o non funzionino correttamente, costringendoti a modificare o a inserire manualmente alcune
              informazioni o preferenze ogni volta che visiterai il sito;
            </li>
          </ul>
        </Text>
        <Text fontSize="3xl"><br />Come posso bloccare i cookie</Text>
        <Text>
          Puoi bloccare i cookie semplicemente attivando l'impostazione sul browser che consente di rifiutare la collocazione dei cookie.
          Se utilizzi le impostazioni del browser per disattivare, rifiutare o bloccare i cookie (inclusi quelli essenziali),
          parte del nostro sito web non funzionerà completamente. In alcuni casi, il nostro sito web potrebbe addirittura
          non essere accessibile. T'informiamo inoltre che se terzi parti utilizzano i cookie, non abbiamo alcun controllo sul loro utilizzo.
          Grazie per aver letto la nostra policy che abbiamo cercato di rendere semplice, chiara e trasparente.
          Ad ogni modo, cerchiamo sempre di migliorare: nel caso tu avessi dei suggerimenti scrivici pure all’indirizzo mail
          TODO , oppure utilizzando la sezione contatti del sito.
        </Text>
      </Flex>
    </PageContainer>
  )
}