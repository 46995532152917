
import { BingAutosuggest, Button, ErrorToast, FileUpload, ImageAssociation, Input, SimpleGrid } from "../../components";
import { useEffect, useState } from "react";
import { Profile } from "../../models";
import { AlertDialog, AlertDialogBody, AlertDialogContent, Text, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Center, Checkbox, Radio, RadioGroup, Stack, Textarea, useDisclosure, useToast, Box } from "@chakra-ui/react";
import { fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { OpeningHoursTable, SuccessFullToast } from "../../components";
import { WorkingHours } from "../../models/working-hours";
import { BingMap } from "../../components/bing-map";
import React from "react";

interface Props {
    onClose: () => void;
}

export function ProfilePage(props: Props) {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null);
    const token = useAccessToken();
    const [profile, setProfile] = useState<Profile>({});
    const [isProfessionalUser, setHoursTableVisibility] = useState<boolean>();
    const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(true);
    const [addressToUse, setAddressToUse] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onPropertyChange = async (propName: string, value: any) => {
        setProfile((prevProfile) => {
            const updatedProfile: any = { ...prevProfile };
            updatedProfile[propName] = value;
            updateHoursTableVisibilityAndContent(updatedProfile);
            updateSaveButton(updatedProfile);

            if (propName === 'address') {
                setAddressToUse(value ?? "");
            }

            return updatedProfile;
        });
    }

    const updateHoursTableVisibilityAndContent = async (value: Profile) => {
        setHoursTableVisibility(value.userType === 1)
    }
    const updateSaveButton = async (value: Profile) => {
        if (value.address && ((value.userType === 1 && value.companyName) || value.userType === 0)) {
            setSaveIsDisabled(false)
        } else {
            setSaveIsDisabled(true)
        }
    }

    useEffect(() => {
        onPropertyChange('address', addressToUse)
        // eslint-disable-next-line
    }, [addressToUse])

    const onSaveUserInfo = async () => {
        setIsSaving(true);
        let url = `${process.env.REACT_APP_API}/api/profileinfo/updateUserProfileInfo`
        const data = new FormData();
        const payload: any = { ...profile };
        const logoImages = payload['logoFile'] as ImageAssociation;
        delete payload['logoFile'];
        if (logoImages) {
            Object.keys(logoImages).forEach((k, i) => {
                data.append(`LogoImage`, logoImages[k]);
            });
        }

        const backgroundImages = payload['backgroundFile'] as ImageAssociation;
        delete payload['backgroundFile'];
        if (backgroundImages) {
            Object.keys(backgroundImages).forEach((k, i) => {
                data.append(`BackgroundFile`, backgroundImages[k]);
            });
        }

        data.append('payload', JSON.stringify(payload));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data,
        })
        setIsSaving(false);
        if (res.status === 200) {
            SuccessFullToast(toast)
            props.onClose()
        }
        else {
            ErrorToast(toast)
        }
    }

    useEffect(() => {
        const fetch = async () => {
            const url = `${process.env.REACT_APP_API}/api/profileinfo/getUserProfileInfo`;
            const respone = await fetchWithAuth(url, token, { method: 'GET' })
            const res = await respone.json()
            if (!res.workingHours) {
                const newWorkingHours: WorkingHours[] = [{ dayNumber: 1, isClosed: false, morningOpeningHour: "08:30", morningClosingHour: "12:30", eveningOpeningHour: "15:00", eveningClosingHour: "18:00" },
                { dayNumber: 2, isClosed: false, morningOpeningHour: "08:30", morningClosingHour: "12:30", eveningOpeningHour: "15:00", eveningClosingHour: "18:00" },
                { dayNumber: 3, isClosed: false, morningOpeningHour: "08:30", morningClosingHour: "12:30", eveningOpeningHour: "15:00", eveningClosingHour: "18:00" },
                { dayNumber: 4, isClosed: false, morningOpeningHour: "08:30", morningClosingHour: "12:30", eveningOpeningHour: "15:00", eveningClosingHour: "18:00" },
                { dayNumber: 5, isClosed: false, morningOpeningHour: "08:30", morningClosingHour: "12:30", eveningOpeningHour: "15:00", eveningClosingHour: "18:00" },
                { dayNumber: 6, isClosed: true, morningOpeningHour: "", morningClosingHour: "", eveningOpeningHour: "", eveningClosingHour: "" },
                { dayNumber: 7, isClosed: true, morningOpeningHour: "", morningClosingHour: "", eveningOpeningHour: "", eveningClosingHour: "" }];
                res.workingHours = newWorkingHours;
            }
            setProfile(res)
            updateHoursTableVisibilityAndContent(res)
            updateSaveButton(res);
            setAddressToUse(res.address ?? "")
        }
        fetch().catch(console.error)
    }, [token])

    return (
        <>
            <>
                <RadioGroup marginLeft="20px" marginRight="20px" marginBottom="20px" value={profile.userType?.toString() ?? "0"} onChange={(e: any) => { onPropertyChange('userType', Number.parseInt(e)) }}>
                    <Stack>
                        <label>Tipo utente:</label>
                        <Radio value="0">
                            Privato
                        </Radio>
                        <Radio value="1">
                            Azienda
                        </Radio>
                    </Stack>
                </RadioGroup>
                <SimpleGrid minChildWidth="350px" spacing="20px" marginLeft="20px" marginRight="20px">
                    <Stack>
                        <Box hidden={!isProfessionalUser} >
                            <Text>Nome azienda (obbligatorio):</Text>
                            <Input placeholder="Inserire nome azienda qui..." size="lg" isInvalid={profile.companyName === ''} value={profile.companyName ?? ''} onChange={(e: any) => { onPropertyChange('companyName', e.target.value) }} />
                        </Box>
                        <Box hidden={!isProfessionalUser} >
                            <Text>Ulteriori informazioni:</Text>
                            <Textarea placeholder="Inserire ulteriori informazioni sull'azienda qui..." size="lg" value={profile.companyInformations ?? ''} onChange={(e: any) => { onPropertyChange('companyInformations', e.target.value) }} />
                        </Box>
                        <Box hidden={!isProfessionalUser}>
                            <FileUpload value={profile.logoUrl} allowMultiple={false} buttonText="Seleziona logo" onChange={(e) => { onPropertyChange('logoFile', e); }} />
                            <FileUpload value={profile.backgroundUrl} allowMultiple={false} buttonText="Seleziona sfondo pagina del venditore" onChange={(e) => { onPropertyChange('backgroundFile', e); }} />
                        </Box>
                        <Box>
                            <Text>Numero di telefono (facoltativo):</Text>
                            <Input maxLength={15} placeholder="Inserire numero di telefono qui..." size="lg" value={profile.phoneNumber ?? ''} onChange={(e: any) => { onPropertyChange('phoneNumber', e.target.value) }} />
                            <Checkbox isDisabled={profile.phoneNumber === undefined || profile.phoneNumber === null || profile.phoneNumber === ''} isChecked={profile.usePhoneNumber && profile.phoneNumber !== undefined && profile.phoneNumber !== null && profile.phoneNumber !== ''} onChange={(e: any) => { onPropertyChange('usePhoneNumber', e.target.checked) }}> Usarlo nell'annuncio </Checkbox>
                        </Box>
                        <Box>
                            <Text>Numero di telefono whatsapp (facoltativo):</Text>
                            <Input maxLength={15} placeholder="Inserire numero di whatsapp qui..." size="lg" onChange={(e: any) => { onPropertyChange('whatsappPhoneNumber', e.target.value) }} />
                            <Checkbox isDisabled={profile.whatsappPhoneNumber === undefined || profile.whatsappPhoneNumber === null || profile.whatsappPhoneNumber === ''} isChecked={profile.useWhatsappNumber && profile.whatsappPhoneNumber !== undefined && profile.whatsappPhoneNumber !== null && profile.whatsappPhoneNumber !== ''} onChange={(e: any) => { onPropertyChange('useWhatsappNumber', e.target.checked) }}> Usarlo nell'annuncio </Checkbox>
                        </Box>
                        <Box style={{ zIndex: 10 }}>
                            <Text>{!isProfessionalUser ? "Comune (obbligatorio):" : "Indirizzo completo (obbligatorio):"}</Text>
                            <BingAutosuggest isInvalid={profile.address === null || profile.address === ''} value={profile.address ?? ''} onChange={(value: string) => { setAddressToUse(value) }} />
                        </Box>
                        <Center>
                            <BingMap width="800px" height="400px" address={addressToUse} />
                        </Center>
                    </Stack>
                </SimpleGrid>
                <OpeningHoursTable hoursTableVisible={isProfessionalUser} workingHours={profile.workingHours} />
                <Center>
                    <Button
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "20px",
                            marginTop: "20px",
                        }}
                        isLoading={isSaving}
                        loadingText={"Salvataggio..."}
                        width="200px"
                        colorScheme="blue"
                        size="lg"
                        type="submit"
                        onClick={onOpen}
                        isDisabled={saveIsDisabled}
                    >Salva</Button>
                </Center>
            </>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={true}>
                <AlertDialogOverlay >
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Aggiorna informazioni
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Sei sicuro di voler salvare?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme='blue' onClick={() => { onSaveUserInfo(); onClose() }} >
                                Salva
                            </Button>
                            <Button ref={cancelRef} onClick={onClose} ml={3}>
                                Annulla
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}