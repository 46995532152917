import { Image } from "@chakra-ui/react";

interface Props {
    position?: string;
}


export function GetCarPositionImage(props: Props) {
    switch (props.position?.toUpperCase()) {
        case "ANTERIORE":
            return (<Image boxSize="90" src="/images/FrontCarIcon.svg" />)
        case "POSTERIORE":
            return (<Image boxSize="90" src="/images/RearCarIcon.svg" />)
        case "LATERALE DESTRO":
            return (<Image boxSize="90" src="/images/RightCarIcon.svg" />)
        case "LATERALE SINISTRO":
            return (<Image boxSize="90" src="/images/LeftCarIcon.svg" />)
        default: return (<></>)
    }
}