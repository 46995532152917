import { AuthProvider, User, UserManager } from 'oidc-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { CircularProgress } from './components';
import { IDENTITY_CONFIG } from './constants';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { AuthSync } from './state/auth';
import { ThemeProvider } from './theme';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * Callback to cleanup url after login
 */
const onSigninCallback = (_user: User | null): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}

// const ablyClient = (Ably as any).Realtime.Promise({ key: process.env.REACT_APP_ABLY_KEY, clientId: 'part-scout' })


root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RecoilRoot>
        <BrowserRouter>
          <AuthProvider autoSignIn={false} userManager={new UserManager({ ...IDENTITY_CONFIG })} onSignIn={onSigninCallback}>
            <AuthSync fallback={<CircularProgress />}>
              <Suspense>
                <App />
              </Suspense>
            </AuthSync>
          </AuthProvider>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
