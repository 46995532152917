import {
    chakra,
    Popover as ChakraPopover,
    PopoverTrigger as ChakraPopoverTrigger,
    PopoverContent as ChakraPopoverContent,
    PopoverArrow as ChakraPopoverArrow,
    PopoverHeader as ChakraPopoverHeader,
    PopoverCloseButton as ChakraPopoverCloseButton,
    PopoverBody as ChakraPopoverBody,
    PopoverFooter as ChakraPopoverFooter
  } from "@chakra-ui/react";
  
  export const Popover = chakra(ChakraPopover);
  export const PopoverTrigger = chakra(ChakraPopoverTrigger);
  export const PopoverContent = chakra(ChakraPopoverContent);
  export const PopoverArrow = chakra(ChakraPopoverArrow);
  export const PopoverHeader = chakra(ChakraPopoverHeader);
  export const PopoverCloseButton = chakra(ChakraPopoverCloseButton);
  export const PopoverBody = chakra(ChakraPopoverBody);
  export const PopoverFooter = chakra(ChakraPopoverFooter);