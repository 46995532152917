import { useCarParts } from "../../state/car-parts";
import { useEffect, useState } from "react";
import { Flex, Tag, TagLabel, Accordion, Text, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Input, InputGroup, InputLeftElement, Stack, Center, Grid, Tooltip, Spinner, InputLeftAddon } from "@chakra-ui/react";
import { FaEuroSign } from "react-icons/fa";
import { SelectedCarPart } from "../../models/selected-car-part";
import { CarPart } from "../../models";

interface Props {
    value: SelectedCarPart[];
    onChange: (value: SelectedCarPart[]) => void;
}

export function AvailableParts(props: Props) {
    let timeoutId: NodeJS.Timeout;
    const availableOptions = useCarParts();
    const [options, setOptions] = useState<SelectedCarPart[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [openedCategory, setOpenedCategory] = useState<string>("");
    const [availableOptionsToRender, setAvailableOptionsToRender] = useState<CarPart[]>([])
    const [searchValue, setSearchValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [accordionIndex, setAccordionIndex] = useState(-1);

    useEffect(() => {
        let uniquePositionsSet: Set<string> = new Set(
            availableOptions.map((carPart) => carPart.position)
        );
        let uniquePositionsArray: string[] = Array.from(uniquePositionsSet);
        setCategories(uniquePositionsArray);
        setOptions(props.value);
    }, [props.value, setOptions, availableOptions]);

    const toggle = (id: string) => {
        let newOpts: SelectedCarPart[] = [];
        if (isSelected(id)) newOpts = onRemove(id);
        else newOpts = onAdd(id);
        props.onChange(newOpts);
    };

    const onAdd = (opt: string) => {
        const cloned = [...options];
        let newOpt: SelectedCarPart = { id: opt, price: "", name: "", additionalNote: "" };
        cloned.push(newOpt);
        setOptions(cloned);
        return cloned;
    };

    const onRemove = (opt: string) => {
        const cloned = [...options];
        const index = cloned.findIndex((o) => o.id === opt);
        cloned.splice(index, 1);
        setOptions(cloned);
        return cloned;
    };

    const onPriceChange = (id: string, price: string) => {
        const newOptions = [...options];
        const newOption = newOptions.find((o) => o.id === id);
        if (newOption) {
            newOption.price = price;
        }
        setOptions(newOptions);
        props.onChange(newOptions);
    }

    const onAdditionalNoteChange = (id: string, additionalNote: string) => {
        const newOptions = [...options];
        const newOption = newOptions.find((o) => o.id === id);
        if (newOption) {
            newOption.additionalNote = additionalNote;
        }
        setOptions(newOptions);
        props.onChange(newOptions);
    }

    const getValueForPart = (id: string) => {
        let res: string = "";
        const newOptions = [...options];
        const newOption = newOptions.find((o) => o.id === id);
        if (newOption) {
            res = newOption.price;
        }
        return res;
    }

    const getAddotionalNoteForPart = (id: string) => {
        let res: string = "";
        const newOptions = [...options];
        const newOption = newOptions.find((o) => o.id === id);
        if (newOption) {
            res = newOption.additionalNote;
        }
        return res;
    }

    const isSelected = (id: string) => {
        return options.find(o => o.id === id);
    };

    const countCategoryItems = (categoryName: string) => {
        let count = 0;
        options.forEach(function (singleOption) {
            count = count + availableOptions.filter(o => o.id === singleOption.id && o.position === categoryName).length;
        })
        return count;
    };

    const AccordionIsClicked = (singleCategory: string) => {
        if (openedCategory === singleCategory) {
            setOpenedCategory("");
            setAvailableOptionsToRender([])
        } else {
            setOpenedCategory(singleCategory);
            setAvailableOptionsToRender(availableOptions.filter((opt) => opt.position === singleCategory));
        }
    };

    const debounceSearch = (value: string) => {
        setLoading(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            handleSearch(value);
        }, 1000);
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setAccordionIndex(-1);
        setOpenedCategory("");
        setAvailableOptionsToRender([]);
        setLoading(false);
    };

    return (
        <Flex width="100%" wrap="wrap" marginTop="20px" marginBottom="20px">
            <label>Seleziona parti:</label>
            <InputGroup>
                <InputLeftAddon>Cerca:</InputLeftAddon>
                <Input onChange={e => debounceSearch(e.target.value)} placeholder="Scrivi qui il pezzo da cercare..." />
            </InputGroup>
            {loading && <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />}
            <Text hidden={loading || availableOptions.filter(opt => searchValue.length >= 3 && opt.name.toUpperCase().includes(searchValue.toUpperCase())).length === 0}>Risultati ricerca:</Text>
            <Text hidden={!(searchValue.length < 3 && searchValue.length > 0)}>Si prega di inserire almeno 3 caratteri</Text>
            <Text hidden={searchValue.length < 3 || loading || availableOptions.some(opt => opt.name.toUpperCase().includes(searchValue.toUpperCase()))}>Nessun risultato per il pezzo cercato</Text>
            <Grid width="100%" gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} hidden={searchValue.length < 3}>
                {availableOptions
                    .filter(opt => searchValue.length >= 3 && opt.name.toUpperCase().includes(searchValue.toUpperCase()))
                    .sort((a, b) => { if (b.isSummaryItem !== a.isSummaryItem) { return b.isSummaryItem ? 1 : -1; } return a.name.localeCompare(b.name); })
                    .map((opt) => (
                        <Stack position="relative">
                            <Tag
                                onClick={() => {
                                    toggle(opt.id);
                                }}
                                size="lg"
                                key={opt.id}
                                borderRadius={opt.isSummaryItem ? "none" : "full"}
                                variant={isSelected(opt.id) ? "solid" : "outline"}
                                colorScheme={opt.isSummaryItem ? "green" : "blue"}
                                marginTop="5px"
                                marginLeft="5px"
                                marginRight="5px"
                                cursor={"pointer"}
                            >
                                <Tooltip label={opt.name}>
                                    <TagLabel>{opt.name}</TagLabel>
                                </Tooltip>
                            </Tag>
                            <Center>
                                <InputGroup hidden={!isSelected(opt.id)} width="90%">
                                    <Input type="text" placeholder="Note/Codice/Altro..." value={getAddotionalNoteForPart(opt.id)} onChange={(e: any) => { onAdditionalNoteChange(opt.id, e.target.value) }} />
                                </InputGroup>
                            </Center>
                            <Center>
                                <InputGroup hidden={!isSelected(opt.id)} width="90%">
                                    <InputLeftElement>
                                        <FaEuroSign />
                                    </InputLeftElement>
                                    <Input type="number" placeholder="Prezzo..." value={getValueForPart(opt.id)} isInvalid={getValueForPart(opt.id) === undefined || getValueForPart(opt.id) === null || getValueForPart(opt.id) === ""} onChange={(e: any) => { onPriceChange(opt.id, e.target.value) }} />
                                </InputGroup>
                            </Center>
                        </Stack>
                    ))}
            </Grid>
            <Accordion width="100%" allowToggle hidden={searchValue.length >= 3} marginTop="20px" index={accordionIndex}>
                {categories.map((singleCategory, index) => (
                    <AccordionItem key={singleCategory} >
                        <h2>
                            <AccordionButton bg={countCategoryItems(singleCategory) ? "lightblue" : "white"} onClick={e => { if (index === accordionIndex) setAccordionIndex(-1); else setAccordionIndex(index); AccordionIsClicked(singleCategory) }}>
                                <Box as="span" flex="1" textAlign="left">
                                    {singleCategory} ({countCategoryItems(singleCategory)})
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel >
                            <Grid gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}>
                                {availableOptionsToRender
                                    .filter(o => o.position === singleCategory)
                                    .sort((a, b) => { if (b.isSummaryItem !== a.isSummaryItem) { return b.isSummaryItem ? 1 : -1; } return a.name.localeCompare(b.name); })
                                    .map((opt) => (
                                        <Stack position="relative">
                                            <Tag
                                                onClick={() => {
                                                    toggle(opt.id);
                                                }}
                                                size="lg"
                                                key={opt.id}
                                                borderRadius={opt.isSummaryItem ? "none" : "full"}
                                                variant={isSelected(opt.id) ? "solid" : "outline"}
                                                colorScheme={opt.isSummaryItem ? "green" : "blue"}
                                                marginTop="5px"
                                                marginLeft="5px"
                                                marginRight="5px"
                                                cursor={"pointer"}
                                            >
                                                <Tooltip label={opt.name}>
                                                    <TagLabel>{opt.name}</TagLabel>
                                                </Tooltip>
                                            </Tag>
                                            <Center>
                                                <InputGroup hidden={!isSelected(opt.id)} width="90%">
                                                    <Input type="text" placeholder="Note/Codice/Altro..." value={getAddotionalNoteForPart(opt.id)} onChange={(e: any) => { onAdditionalNoteChange(opt.id, e.target.value) }} />
                                                </InputGroup>
                                            </Center>
                                            <Center>
                                                <InputGroup hidden={!isSelected(opt.id)} width="90%">
                                                    <InputLeftElement>
                                                        <FaEuroSign />
                                                    </InputLeftElement>
                                                    <Input type="number" placeholder="Prezzo..." value={getValueForPart(opt.id)} isInvalid={getValueForPart(opt.id) === undefined || getValueForPart(opt.id) === null || getValueForPart(opt.id) === ""} onChange={(e: any) => { onPriceChange(opt.id, e.target.value) }} />
                                                </InputGroup>
                                            </Center>
                                        </Stack>
                                    ))}
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    );
}
