import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, Icon } from "../base";
import { PaginationButton } from "./PaginationButton";
import { useBreakpointValue } from "@chakra-ui/react";

interface Props {
  total: number,
  current: number,
  onPageChange: (page: number) => void
}

export function Pagination(props: Props) {
  const visiblePages = useBreakpointValue({ base: 5, md: 10 });

  const onChange = (page: number) => {
    props?.onPageChange(page);
  }

  const getPages = () => {
    if (visiblePages) {
      let nPages = props.total > visiblePages ? visiblePages : props.total;
      let start = 1;
      if (props.total > visiblePages && props.current > Math.floor(visiblePages / 2)) {
        nPages = Math.min(props.current + Math.floor(visiblePages / 2), props.total);
        start = Math.max(props.current - Math.floor(visiblePages / 2), 1);
      }
      const res: JSX.Element[] = [];
      for (let i = start; i <= nPages; i++) {
        const page =
          <PaginationButton
            active={i === props.current}
            onClick={() => onChange(i)}
          >{i.toString()}</PaginationButton>
        res.push(page);
      }

      return res;
    }
  }

  return (
    <Flex
      p={10}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex>
        <PaginationButton onClick={() => onChange(props.current - 1)} disabled={props.current === 1}>
          <Icon
            as={ChevronLeftIcon}
            color="gray.700"
            _dark={{
              color: "gray.200",
            }}
            boxSize={4}
          />
        </PaginationButton>
        {getPages()}
        <PaginationButton onClick={() => onChange(props.current + 1)} disabled={props.current === props.total} >
          <Icon
            as={ChevronRightIcon}
            color="gray.700"
            _dark={{
              color: "gray.200",
            }}
            boxSize={4}
          />
        </PaginationButton>
      </Flex>
    </Flex>
  )
}