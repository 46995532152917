import { useRecoilValue } from "recoil";
import { Filters } from "../../models";
import { $car, $carList, $latestCars } from "./selectors";

export function useLatestCars(page: number) {
  const cars = useRecoilValue($latestCars(page));
  return cars;
}

export function useCarList(filters: Filters) {
  const serialized = JSON.stringify(filters);
  const carList = useRecoilValue($carList(serialized));
  return carList;
}

export function useCar(id: string) {
  const car = useRecoilValue($car(id));
  return car;
}
