import { useNavigate } from "react-router-dom";

interface Props {
  w: number;
  h: number;
  onClick?: () => void;
}

export function Logo(props: Props) {
  const navigate = useNavigate();

  return (
    <img alt="" height={props.h} width={props.w} src="/images/logo.svg" onClick={() => navigate("/")} style={{cursor: 'pointer'}}/>
  );
}


