import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModelAndManufacturers } from "../../state/model-manufacturers";
import { Button, Select, VStack } from "../base";
import { Input, InputGroup, InputRightElement, Text, Tooltip } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";

export function SearchPanel() {
    const navigate = useNavigate();
    const [selectedManufacturer, setSelectedManufacturer] = useState<string | undefined>(undefined);
    const [selectedModel, setSelectedModel] = useState<string | undefined>(undefined);
    const [searchCode, setSearchCode] = useState<string | null>("");
    const searchButtonDisabled = (searchCode === undefined || searchCode === null || searchCode === "") && (selectedManufacturer === undefined || selectedManufacturer === null || selectedManufacturer === "");

    const manufacturersAndModels = useModelAndManufacturers();

    const onSearch = () => {
        if (searchCode === undefined || searchCode === null || searchCode === "") {
            navigate({
                pathname: "/car-list",
                search: `?selectedManufacturer=${selectedManufacturer}&selectedModel=${selectedModel}`
            });
        }
        else {
            navigate({
                pathname: "/car-list",
                search: `?searchCode=${searchCode}`
            });
        }
    }

    const onManufacturerChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setSelectedManufacturer(newValue);
        setSelectedModel(undefined);
    }

    const onModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setSelectedModel(newValue);
    }

    return (
        <VStack spacing="2" width="full">
            <Select
                placeholder='Marca'
                value={selectedManufacturer as string}
                onChange={onManufacturerChange}
                size="lg"
                color="brand.900">
                {Object.keys(manufacturersAndModels).map((key) => (
                    <option key={manufacturersAndModels[key][0].manufacturerId} value={manufacturersAndModels[key][0].manufacturerId}>
                        {manufacturersAndModels[key][0].manufacturerName}
                    </option>
                ))}
            </Select>
            <Select
                placeholder='Modello'
                size="lg"
                value={selectedModel as string}
                onChange={onModelChange}
                color="brand.900">
                {selectedManufacturer && manufacturersAndModels[selectedManufacturer as string].map((m) => (
                    <option key={m.modelId} value={m.modelId}>
                        {m.modelName}
                    </option>
                ))}
            </Select>
            <Text>Oppure cerca per codice ricambio:</Text>
            <InputGroup size="lg" paddingBottom="10px" >
                <Input type="text" placeholder="Codice ricambio/colore..." size="lg" value={searchCode === null ? "" : searchCode} onChange={(e: any) => { setSearchCode(e.target.value) }} />
                <InputRightElement onClick={(e: any) => { setSearchCode(null) }} cursor="pointer" hidden={searchCode === undefined || searchCode === null || searchCode === ""}>
                    <MdClear size="lg" />
                </InputRightElement>
            </InputGroup>
            <Tooltip label="Seleziona almeno una marca oppure un codice ricambio" isDisabled={!searchButtonDisabled} hasArrow>
                <Button
                    //variant="ghost"
                    size="lg"
                    type="submit"
                    minWidth="250px"
                    isDisabled={searchButtonDisabled}
                    onClick={onSearch}>
                    Cerca
                </Button>
            </Tooltip>
        </VStack>
    )
}