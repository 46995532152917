import { Skeleton, Stack } from "../base";

export function FilterSkeleton() {
  return (
    <Stack>
      <Skeleton height={"48px"} width={"350px"} />
      <Skeleton height={"48px"} width={"350px"} />
      <Skeleton alignSelf={"center"} height={"48px"} width={"95px"} />
    </Stack>
  )
}