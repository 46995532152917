import { Car } from "../../models";
import { Button, Image } from "../base";
import './index.css';
import { Share } from '../share';
import { Flex } from '@chakra-ui/react';
import { Favorite } from '../favorite';
import { useMediaQuery } from '@chakra-ui/react';

interface Props {
    onClick?: (id: string) => void;
    car: Car
    hideCarActions?: boolean
}

export function CarCard(props: Props) {
    const [isOldMobile] = useMediaQuery('(max-width: 375px)');
    const [isMobile] = useMediaQuery('(max-width: 420px)');

    const onClick = () => {
        if (props.onClick)
            props.onClick(props.car.id);
    }

    return (
        <div className="car_wrapper" style={{ width: isOldMobile ? '300px' : isMobile ? '350px' : '400px', height: "400px" }}>
            <div className="car_item" onClick={onClick}>
                <div className="car_image">
                    <Button as="a">
                        <Image alt="" src={`${process.env.REACT_APP_API}/${props.car.previewPhotoUrl}` || '/images/missing_car.jpg'} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/missing_car.jpg"; }} />
                    </Button>
                    <div className="car_stats">
                        <div className="car_price">
                            <div className="car_price_inner">€{props.car.price}</div>
                        </div>
                    </div>
                </div>
                <div className="car_content">
                    {props.hideCarActions ? (<></>
                    ) : (
                        <div className="car_actions">
                            <div className="car_actions_info"></div>
                            <div className="car_actions_icons" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                <Flex gap={1}>
                                    <Share url={`${process.env.REACT_APP_CLIENT}/car-detail/${props.car.id}`} />
                                    <Favorite id={props.car.id} />
                                </Flex>
                            </div>
                        </div>
                    )}
                    <h2 className="car_title">
                        <Button as="a">{props.car.carTitle?.substring(0, 20)}{props.car.carTitle?.length > 20 ? "..." : ""}</Button>
                    </h2>
                    <div className="car_description">
                        <p>{props.car.description?.substring(0, 30)}{props.car.description?.length > 30 ? "..." : ""}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}