import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "../base";
import { useEffect, useState } from "react";
import { Text, Center, Stack, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, useMediaQuery } from "@chakra-ui/react";
import { fetchWithAuth } from "../../utils";
import { Faq } from "../../models/faq";
import { Link } from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export function FaqPage(props: Props) {
    const [faqs, setFaqs] = useState<Faq[]>();
    const [isDesktop] = useMediaQuery("(min-width: 768px)");

    useEffect(() => {
        if (props.isOpen) {
            const fetch = async () => {
                const url = `${process.env.REACT_APP_API}/api/faq/getAllFaqs`;
                const respone = await fetchWithAuth(url, "", {
                    method: 'GET'
                })
                const res = await respone.json()
                setFaqs(res);
            }
            fetch().catch(console.error)
        }
    }, [props.isOpen])

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><Center>FAQ</Center></ModalHeader>
                <ModalCloseButton />
                <Stack margin={"10px"}>
                    <Accordion allowMultiple={isDesktop} gap={6}>
                        {faqs?.map(f =>
                            <AccordionItem borderRadius={"2xl"} marginBottom={"10px"}>
                                <h2>
                                    <AccordionButton backgroundColor={"blue.100"} borderRadius={"2xl"}>
                                        <Box as="span" flex='1' textAlign='left' >
                                            {f.question}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} backgroundColor={"gray.100"} borderRadius={"2xl"}>
                                    {f.answer}
                                </AccordionPanel>
                            </AccordionItem>
                        )}

                    </Accordion>
                    <Box as="span" flex='1' textAlign='center' >
                        <Stack>
                            <Text>
                                Hai altre domande o dubbi?
                            </Text>
                            <Text>
                                Scrivici pure una mail cliccando <Link to={"mailto:info@partscout.it"} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>qui</Link> a info@partscout.it
                            </Text>
                        </Stack>
                    </Box>
                </Stack>
            </ModalContent>
        </Modal>
    )
}