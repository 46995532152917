export function fetchWithAuth(
  url: string,
  token: string,
  init?: RequestInit
): Promise<Response> {
  return fetch(url, {
    ...init,
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Requested-With": "XMLHttpRequest",
      ...init?.headers,
    },
  });
}
