export function SuccessFullToast(toast: any, title?: string, description?: string) {
    return toast(
        {
            title: title ?? 'Modifiche salvate.',
            description: description ?? "Modifiche salvate correttamente",
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top'
        }
    )
}

export function InformationToast(toast: any, title: string, description: string) {
    return toast({
        title: title,
        description: description,
        status: 'info',
        duration: 6000,
        isClosable: true,
        position: 'top'
    })
}

export function ErrorToast(toast: any, title?: string, description?: string) {
    return toast({
        title: title ?? 'Modifiche NON salvate.',
        description: description ?? "C'è stato un problema durante il salvataggio.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top'
    })
}