import { CopyIcon } from "@chakra-ui/icons";
import { Flex, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text, useToast } from "@chakra-ui/react";
import { BsShare } from "react-icons/bs";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { InformationToast } from "../toasts";

interface Props {
    url: string;
}

export function Share(props: Props) {
    const toast = useToast();
    return (
        <Popover>
            <PopoverTrigger>
                <Flex gap={1} cursor="pointer">
                    <IconButton aria-label="Condividi" icon={<BsShare />} size="xs" />
                    <Text as="u">Condividi</Text>
                </Flex>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Seleziona dove condividere:</PopoverHeader>
                <PopoverBody>
                    <Stack>
                        <HStack onClick={(e: any) => { navigator.clipboard.writeText(props.url); InformationToast(toast, "Indirizzo copiato", "Indirizzo copiato negli appunti!") }} cursor="pointer">
                            <IconButton aria-label="Condividi" icon={<CopyIcon />} size="sm" isRound={true} variant='solid' />
                            <Text>Copia link negli appunti</Text>
                        </HStack>
                        <EmailShareButton url={props.url} className="Demo__some-network__share-button">
                            <HStack>
                                <EmailIcon size={32} round />
                                <Text>Condividi tramite mail</Text>
                            </HStack>
                        </EmailShareButton>
                        <WhatsappShareButton url={props.url} className="Demo__some-network__share-button">
                            <HStack>
                                <WhatsappIcon size={32} round />
                                <Text>Condividi su Whatsapp</Text>
                            </HStack>
                        </WhatsappShareButton>
                        <FacebookShareButton url={props.url} className="Demo__some-network__share-button">
                            <HStack>
                                <FacebookIcon size={32} round />
                                <Text>Condividi su Facebook</Text>
                            </HStack>
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={props.url} className="Demo__some-network__share-button" appId="521270401588372">
                            <HStack>
                                <FacebookMessengerIcon size={32} round />
                                <Text>Condividi su Messenger</Text>
                            </HStack>
                        </FacebookMessengerShareButton>
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}