import { useRecoilValue } from "recoil";
import { $token, $user } from "./selectors";

export function useAccessToken() {
  const token = useRecoilValue($token);
  return token;
}

export function useUser() {
  const user = useRecoilValue($user);
  return user;
}