/**
 * group an array for a given property of the object contained in the array
 * @param array the array to group
 * @param prop the property to group by
 */
export function groupBy<T>(array: T[], prop: string): { [key: string]: T[] } {
  return array.reduce((prevValue: any, currentValue: any) => {
    (prevValue[currentValue[prop]] = prevValue[currentValue[prop]] || []).push(
      currentValue
    );
    return prevValue;
  }, {});
}
