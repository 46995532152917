import { Button } from "../base";

interface Props {
  disabled?: boolean
  active?: boolean
  children?: JSX.Element | string
  onClick?: () => void
}

export function PaginationButton(props: Props) {
  const activeStyle = {
    bg: "brand.600",
    _dark: {
      bg: "brand.500",
    },
    color: "white",
  };
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      rounded="md"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      color="gray.700"
      opacity={props.disabled ? 0.6 : 1}
      _hover={!props.disabled ? activeStyle : undefined}
      cursor={props.disabled ? 'not-allowed' : 'pointer'}
      isDisabled={props.disabled}
      onClick={props?.onClick}
      {...(props.active && activeStyle)}
    >
      {props.children}
    </Button>
  )
}