import {
    Modal as ChakraModal,
    ModalOverlay as ChakraOverlay,
    ModalContent as ChakraModalContent,
    ModalHeader as ChakraModalHeader,
    ModalFooter as ChakraModalFooter,
    ModalBody as ChakraModalBody,
    ModalCloseButton as ChakraCloseButton,
    useDisclosure as chakraUseDisclosure,
    chakra,
  } from '@chakra-ui/react'


  export const Modal = chakra(ChakraModal);
  export const ModalOverlay = chakra(ChakraOverlay);
  export const ModalContent = chakra(ChakraModalContent);
  export const ModalHeader = chakra(ChakraModalHeader);
  export const ModalBody = chakra(ChakraModalBody);
  export const ModalFooter = chakra(ChakraModalFooter);
  export const ModalCloseButton = chakra(ChakraCloseButton);
  export const useDisclosure = chakraUseDisclosure;