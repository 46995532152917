import { ChangeEvent, useEffect, useState } from "react";
import { useModelAndManufacturers } from "../../state/model-manufacturers";
import { Button, Select } from "../base";
import { Divider, Grid, Input, InputGroup, InputLeftAddon, InputRightElement, Stack, Text, Tooltip } from "@chakra-ui/react";
import { Select as MultiSelect } from "chakra-react-select";
import { useCarParts } from "../../state/car-parts";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { SelectedCarPartFull } from "../../models/selected-car-part";
import { MdClear } from "react-icons/md";

interface Props {
  manufacturer: string | null;
  model: string | null;
  searchCode: string | null;
  yearMin: string | null;
  yearMax: string | null;
  priceMin: string | null;
  priceMax: string | null;
  partsToSearch: string[] | null;
  onApply: (manufacturer: string | null, model: string | null, searchCode: string | null, minimumYear: string | null, maximumYear: string | null, minimumPrice: string | null, maximumprice: string | null, partsToSearch: string[] | null) => void;
}

export function FilterPanel(props: Props) {
  const allParts = useCarParts();
  const [partsCategories, setPartsCategories] = useState<string[]>([]);
  const [selectedCarParts, setSelectedCarParts] = useState<SelectedCarPartFull[]>([]);
  const [selectedCategorySummaryItems, setSelectedCategorySummaryItems] = useState<string[]>([]);
  const [selectPartsHidden, setPartSelectionHidden] = useState<boolean>(props.partsToSearch?.length !== undefined && props.partsToSearch?.length === 0);
  const manufacturersAndModels = useModelAndManufacturers();
  const [selectedManufacturer, setSelectedManufacturer] = useState<string | null>(props.manufacturer);
  const [selectedModel, setSelectedModel] = useState<string | null>(props.model);
  const [searchCode, setSearchCode] = useState<string | null>(props.searchCode);
  const allYearsValues = GetAllYearsValues();
  const [selectedMinYear, setSelectecMinYear] = useState<string | null>(props.yearMin);
  const [selectedMaxYear, setSelectecMaxYear] = useState<string | null>(props.yearMax);
  const [minimumYears, setMinimumYears] = useState<string[]>(allYearsValues);
  const [maximumYears, setMaximumYears] = useState<string[]>(allYearsValues);
  const allMoneyValues = ["0", "500", "1000", "1500", "2000", "2500", "3000", "3500", "4000", "4500", "5000", "10000", "20000", "30000", "40000", "50000", "60000", "70000", "80000", "90000", "100000"];
  const [selectedMinPrice, setSelectecMinPrice] = useState<string | null>(props.priceMin);
  const [selectedMaxPrice, setSelectecMaxPrice] = useState<string | null>(props.priceMax);
  const [minimumPrices, setMinimumPrices] = useState<string[]>(allMoneyValues);
  const [maximumPrices, setMaximumPrices] = useState<string[]>(allMoneyValues);
  const searchCodeIsPopulated = (searchCode !== undefined && searchCode !== null && searchCode !== "");
  const searchButtonDisabled = (searchCode === undefined || searchCode === null || searchCode === "") && (selectedManufacturer === undefined || selectedManufacturer === null || selectedManufacturer === "");

  useEffect(() => {
    let uniquePositionsSet: Set<string> = new Set(
      allParts.map((carPart) => carPart.position)
    );
    let uniquePositionsArray: string[] = Array.from(uniquePositionsSet);
    setPartsCategories(uniquePositionsArray);

    if (Array.isArray(props.partsToSearch)) {
      let selectedParts: SelectedCarPartFull[] = [];
      let positions: string[] = [];
      for (const item of props.partsToSearch) {
        const newItemToPut = allParts.find(p => p.id === item);
        if (newItemToPut) {
          if (newItemToPut.isSummaryItem) positions.push(newItemToPut.position);
          selectedParts.push({ id: newItemToPut.id, name: (newItemToPut.isSummaryItem ? "Tutti i pezzi della categoria" : newItemToPut.name), position: newItemToPut.position, price: "", additionalNote: "" });
        }
      };
      setSelectedCarParts(selectedParts);
      setSelectedCategorySummaryItems(positions);
    }
  }, [props.partsToSearch, allParts]);

  const onManufacturerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSelectedManufacturer(newValue);
    setSelectedModel(null);
  };

  const onModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSelectedModel(newValue);
  };

  function GetAllYearsValues(): string[] {
    const startYear = 1900;
    const currentYear = new Date().getFullYear();
    const yearsArray: string[] = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year.toString());
    }
    return yearsArray;
  }

  const onApply = () => {
    const carPartsToSearch: string[] = selectedCarParts.map(obj => obj.id);
    props.onApply(selectedManufacturer, selectedModel, searchCode, selectedMinYear, selectedMaxYear, selectedMinPrice, selectedMaxPrice, carPartsToSearch);
  }

  function minimumYearChanged(value: any) {
    if (value) {
      let index = allYearsValues.findIndex((element) => element === value)
      let newValues = allYearsValues.slice(0, index);
      setMaximumYears(newValues);
    } else {
      setMaximumYears(allYearsValues)
    }
    setSelectecMinYear(value);
  }

  function maximumYearChanged(value: any) {
    if (value) {
      let index = allYearsValues.findIndex((element) => element === value)
      let newValues = allYearsValues.slice(index + 1);
      setMinimumYears(newValues);
    } else {
      setMinimumYears(allYearsValues)
    }
    setSelectecMaxYear(value);
  }

  function minimumPriceChanged(value: any) {
    if (value) {
      let index = allMoneyValues.findIndex((element) => element === value)
      let newValues = allMoneyValues.slice(index + 1);
      setMaximumPrices(newValues);
    } else {
      setMaximumPrices(allMoneyValues)
    }
    setSelectecMinPrice(value);
  }

  function maximumPriceChanged(value: any) {
    if (value) {
      let index = allMoneyValues.findIndex((element) => element === value)
      let newValues = allMoneyValues.slice(0, index);
      setMinimumPrices(newValues);
    } else {
      setMinimumPrices(allMoneyValues)
    }
    setSelectecMaxPrice(value);
  }

  function carPartSelected(e: [{ label: string, value: string }] | { label: string, value: string }, singleCategory: string) {
    let newSelectedCarParts = [...selectedCarParts].filter(p => p.position !== singleCategory);

    if (Array.isArray(e)) {
      for (const item of e) {
        const newItemToPut = allParts.find(p => p.id === item.value);
        if (newItemToPut) {
          if (newItemToPut.isSummaryItem) {
            newSelectedCarParts = [...selectedCarParts].filter(p => p.position !== singleCategory);
            newSelectedCarParts.push({ id: item.value, position: singleCategory, name: item.label, price: "", additionalNote: "" })
            let newSelectedCategorySummaryItems = [...selectedCategorySummaryItems].filter(p => p !== singleCategory);
            newSelectedCategorySummaryItems.push(singleCategory);
            setSelectedCategorySummaryItems(newSelectedCategorySummaryItems)
            break;
          }
          else {
            newSelectedCarParts.push({ id: item.value, position: singleCategory, name: item.label, price: "", additionalNote: "" })
          }
        }
      };
    }
    else if (e) {
      const newItemToPut = allParts.find(p => p.id === e.value);
      let newSelectedCategorySummaryItems = [...selectedCategorySummaryItems].filter(p => p !== singleCategory);
      if (newItemToPut) {
        if (newItemToPut.isSummaryItem) {
          newSelectedCarParts = [...selectedCarParts].filter(p => p.position !== singleCategory);
          newSelectedCarParts.push({ id: e.value, position: singleCategory, name: e.label, price: "", additionalNote: "" })
          newSelectedCategorySummaryItems.push(singleCategory);
        }
        else {
          newSelectedCarParts.push({ id: e.value, position: singleCategory, name: e.label, price: "", additionalNote: "" })
        }
        setSelectedCategorySummaryItems(newSelectedCategorySummaryItems)
      }
    }
    setSelectedCarParts(newSelectedCarParts);
  }

  function getSelectValuesForSingleCategory(singleCategory: string): Array<{ label: string, value: string }> {
    let newSelectedCarParts = [...selectedCarParts].filter(p => p.position === singleCategory);
    let res: Array<{ label: string, value: string }> = [];
    newSelectedCarParts.map(p => res.push({ label: p.name, value: p.id }))
    return res;
  }

  return (
    <Stack marginBottom="20px" width="100%">
      <Text>Cerca filtrando per marca, modello, categoria ricambi, ecc..</Text>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={3}>
        <InputGroup size="lg">
          <InputLeftAddon children="Marca: " />
          <Select placeholder="Marca" value={selectedManufacturer as string} isDisabled={searchCodeIsPopulated} onChange={onManufacturerChange} size="lg" color="brand.900" >
            {Object.keys(manufacturersAndModels)?.map((key) => (
              <option key={manufacturersAndModels[key][0].manufacturerId} value={manufacturersAndModels[key][0].manufacturerId}>
                {manufacturersAndModels[key][0].manufacturerName}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup size="lg">
          <InputLeftAddon children="Modello: " />
          <Select placeholder="Modello" size="lg" value={selectedModel as string} isDisabled={searchCodeIsPopulated} onChange={onModelChange} color="brand.900">
            {selectedManufacturer && manufacturersAndModels[selectedManufacturer as string]?.map((m) => (
              <option key={m.modelId} value={m.modelId}>
                {m.modelName}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup size="lg" >
          <InputLeftAddon children="Anno: " />
          <Select placeholder="Da" value={selectedMinYear as string} isDisabled={searchCodeIsPopulated} onChange={(e: any) => { minimumYearChanged(e.target.value) }}>
            {minimumYears.map((each, index) => (<option value={each.toString()}>{each.toString()}</option>))}
          </Select>
          <Select placeholder="A" value={selectedMaxYear as string} isDisabled={searchCodeIsPopulated} onChange={(e: any) => { maximumYearChanged(e.target.value) }}>
            {maximumYears.map((each, index) => (<option value={each.toString()}>{each.toString()}</option>))}
          </Select>
        </InputGroup>
        <InputGroup size="lg" >
          <InputLeftAddon children="Prezzo: " />
          <Select placeholder="Da" value={selectedMinPrice as string} isDisabled={searchCodeIsPopulated} onChange={(e: any) => { minimumPriceChanged(e.target.value) }}>
            {minimumPrices.map((each, index) => (<option value={each}>€ {Number.parseInt(each).toLocaleString()}</option>))}
          </Select>
          <Select placeholder="A" value={selectedMaxPrice as string} isDisabled={searchCodeIsPopulated} onChange={(e: any) => { maximumPriceChanged(e.target.value) }}>
            {maximumPrices.map((each, index) => (<option value={each}>€ {Number.parseInt(each).toLocaleString()}</option>))}
          </Select>
        </InputGroup>
      </Grid>
      <Text onClick={(e: any) => { setPartSelectionHidden(!selectPartsHidden) }}>Seleziona parti (clicca per aprire il dettaglio dei ricambi):<ArrowDownIcon display={!selectPartsHidden ? "none" : "initial"} /><ArrowUpIcon display={selectPartsHidden ? "none" : "initial"} /></Text>
      <Divider />
      <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} paddingBottom="10px" columnGap="2" hidden={selectPartsHidden}>
        {partsCategories.map((singleCategory) => (
          <Stack paddingBottom="10px">
            <Text>{singleCategory}</Text>
            <MultiSelect isDisabled={searchCodeIsPopulated}
              id={singleCategory.replace(" ", "") + "Select"}
              isMulti={!(selectedCategorySummaryItems.filter(c => c === singleCategory).length === 1)}
              tagVariant="solid"
              colorScheme="blue"
              options={
                allParts
                  .filter((opt) => opt.position === singleCategory)
                  .sort((a, b) => { if (b.isSummaryItem !== a.isSummaryItem) { return b.isSummaryItem ? 1 : -1; } return a.name.localeCompare(b.name); })
                  .map((opt) => ({ label: opt.isSummaryItem ? "Tutti i pezzi della categoria" : opt.name, value: opt.id }))
              }
              placeholder="Seleziona parti..."
              closeMenuOnSelect={false}
              size="lg"
              isClearable={true}
              value={getSelectValuesForSingleCategory(singleCategory)}
              onChange={(e: any) => {
                carPartSelected(e, singleCategory);
              }}
            />
          </Stack>
        ))
        }
      </Grid>
      <Divider hidden={selectPartsHidden} />
      <Text>Oppure cerca solo per codice (verranno cercati tutti i codici e le descrizioni):</Text>
      <InputGroup size="lg" paddingBottom="10px" >
        <InputLeftAddon children="Codice: " />
        <Input type="text" placeholder="Codice ricambio/colore..." size="lg" value={searchCode === null ? "" : searchCode} onChange={(e: any) => { setSearchCode(e.target.value) }} />
        <InputRightElement onClick={(e: any) => { setSearchCode(null) }} cursor="pointer" hidden={searchCode === undefined || searchCode === null || searchCode === ""}>
          <MdClear size="lg" />
        </InputRightElement>
      </InputGroup>
      <Tooltip label="Seleziona almeno una marca oppure un codice ricambio" isDisabled={!searchButtonDisabled} hasArrow>
        <Button
          size="lg"
          type="submit"
          minWidth="250px"
          isDisabled={searchButtonDisabled}
          onClick={onApply}>
          Cerca
        </Button>
      </Tooltip>
    </Stack>
  );
}


