import { Input, Spinner } from "@chakra-ui/react";
import { CarCard, Flex } from "../../components";
import { Car } from "../../models";
import { useState } from "react";

interface Props {
  onCarSelected: (id: string) => void,
  gridWidth: number,
  listings: Car[]
}


export function ListingGrid(props: Props) {
  let timeoutId: NodeJS.Timeout;
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onCarSelected = (id: string) => {
    props.onCarSelected(id);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setLoading(false);
  };

  const debounceSearch = (value: string) => {
    setLoading(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      handleSearch(value);
    }, 1000);
  };

  const filteredListings = props.listings.filter((car) => {
    const titleMatch =
      car.carTitle && car.carTitle.toLowerCase().includes(searchValue.toLowerCase());
    const descriptionMatch =
      car.description &&
      car.description.toLowerCase().includes(searchValue.toLowerCase());

    return titleMatch || descriptionMatch;
  });

  return (
    <Flex
      flexDirection="column"
      paddingLeft="10px"
      paddingRight="10px"
      marginTop="10px"
      alignItems="center"
      gap={4}
    >
      <Input hidden={props.listings.length <= 10}
        placeholder="Cerca per titolo/descrizione..."
        onChange={(e) => debounceSearch(e.target.value)}
      ></Input>
      {loading && <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />}
      <Flex
        flexWrap="wrap"
        gap={5}
        justifyContent="center"
        alignItems="center"
      >
        {filteredListings.map((c) => (
          <CarCard
            key={c.id}
            onClick={onCarSelected}
            car={c}
            hideCarActions={true}
          />
        ))}
      </Flex>
    </Flex>
  );
}