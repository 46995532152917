import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "../base";
import { ProfilePage } from "../../pages/profile-page";

interface Props {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export function UserProfile(props: Props) {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Profilo Utente</ModalHeader>
                <ModalCloseButton />
                <ProfilePage onClose={props.onClose} />
            </ModalContent>
        </Modal>
    )
}