import { useCallback, useEffect, useRef } from "react"

interface Props {
    width: string
    height: string
    address: string
}

export function BingMap(props: Props) {

    const mapContainer = useRef<HTMLDivElement>(null)
    const map = useRef(null)

    const removePushpins = (map: any, Maps: any) => {
        for (let i = map.entities.getLength() - 1; i >= 0; i--) {
            const pushpin = map.entities.get(i)
            if (pushpin instanceof Maps.Pushpin) {
                map.entities.removeAt(i)
            }
        }
    }

    const geocodeQuery = useCallback((query: string, map: any, Maps: any) => {
        removePushpins(map, Maps)
        Maps.loadModule('Microsoft.Maps.Search', function () {
            const searchManager = new Maps.Search.SearchManager(map);
            var searchRequest = {
                where: query,
                callback: (r: any) => {
                    //Add the first result to the map and zoom into it.
                    if (r && r.results && r.results.length > 0) {
                        var pin = new Maps.Pushpin(r.results[0].location);
                        map.entities.push(pin);

                        map.setView({ bounds: r.results[0].bestView });
                    }
                }
            };

            //Make the geocode request.
            searchManager.geocode(searchRequest);
        })
    }, [])

    const makeMap = useCallback(() => {
        const windowObj = window as any
        const { Maps } = windowObj.Microsoft
        if (!map.current) {
            map.current = new Maps.Map(mapContainer.current, {
                credentials: 'Ak1_Mwx_rKv7aNznrHDH09Txfu-2sjzjeNBiuAJb-LPUZXI1Dau5O9tYG1lfUBt3',
                enableCORS: true
            })
        }

        if (props.address && props.address !== '') {
            geocodeQuery(props.address, map.current, Maps)
        }
    }, [props.address, geocodeQuery])


    useEffect(() => {
        const windowObj = window as any
        windowObj.makeMap = makeMap
        if (windowObj.Microsoft && windowObj.Microsoft.Maps) {
            makeMap()
        } 
    }, [makeMap, geocodeQuery])

    return (
        <>
            <div ref={mapContainer} style={{ height: props.height ?? '500px', width: props.width ?? '500px', zIndex: "1" }}></div>
        </> 
    )
}