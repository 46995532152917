import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import { Center, useColorModeValue } from "@chakra-ui/react";
import { CarDescription, Flex, PageContainer, VendorSummary, } from "../../components";
import { useCar } from "../../state/cars";
import "./index.css";
import { Helmet } from "react-helmet";
import { Divider, HStack, Text, Grid, GridItem, Spacer } from "@chakra-ui/react";
import { BingMap } from "../../components/bing-map";
import { Share } from "../../components/share";
import { Favorite } from "../../components/favorite";

export function CarDetail() {
  const { id } = useParams();
  const car = useCar(id as string);

  return (
    <PageContainer>
      <Helmet>
        <title>Part Scout | Car Detail: {car.carTitle}</title>
        <meta name="description" content={"PartScout a site for all your car rebuild needs | " + car.description} />
        <meta property="og:title" content={car.carTitle} />
        <meta property="og:description" content={car.description} />
        <meta property="og:image" content={car.previewPhotoUrl} />
      </Helmet>

      <Grid templateColumns={{ lg: 'repeat(10, 1fr)', base: 'repeat(7, 1fr)' }} templateRows={{ lg: 'repeat(10, 1fr)', base: 'repeat(7, 1fr)' }} columnGap={10} style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
        <GridItem colSpan={7} rowSpan={10}>
          <div className="slider_wrapper">
            <div className="slider_container">
              <Carousel showArrows={true} showThumbs={true} infiniteLoop={true} >
                {car.images.map((img, i) =>
                  <div key={img + i}>
                    <img alt="" src={`${process.env.REACT_APP_API}/${img}`} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/missing_car.jpg"; }} />
                  </div>)}
              </Carousel>
            </div>
          </div>
        </GridItem>
        <GridItem colSpan={{ base: 7, lg: 3 }} marginTop="20px" rowSpan={2}>
          <HStack>
            <Favorite id={car.id} />
            <Spacer />
            <Share url={window.location.href} />
          </HStack>
          <Text fontSize={20} fontWeight="bold">{car.manufacturerName} {car.modelName} - anno {car.year}</Text>
          <Text>{car.carTitle}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 7, lg: 3 }} marginTop="20px" rowSpan={5} bg={useColorModeValue("gray.100", "gray.900")} rounded="full" borderRadius="2xl">
          <VendorSummary vendorId={car.vendorId} />
        </GridItem>
      </Grid>
      <Flex direction="row" wrap="wrap" marginBottom="20px" style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }} >
        <div className="description_wrapper">
          <CarDescription car={car} />
        </div>
      </Flex>
      <Divider marginTop="5px" />
      <Grid templateColumns='repeat(2, 1fr)' gap={10} style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto", marginBottom: "40px" }}>
        <GridItem colSpan={2} marginTop="20px">
          <Center>
            <BingMap address={car.address} width="1000px" height="500px" />
          </Center>
        </GridItem>
      </Grid >
      {/* {(auth && auth.userData) && <Chat channelName={`${id}`} />} */}
    </PageContainer >
  );
}
