import { CloseIcon } from "@chakra-ui/icons";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { MdCropRotate } from "react-icons/md";
import { Button, Flex, IconButton, Input } from "../base";

export type ImageAssociation = { [key: string]: File };

interface Props {
  value: string | undefined,
  buttonText: string,
  allowMultiple: boolean,
  onChange: (file: ImageAssociation) => void,
}

export function FileUpload(props: Props) {
  const [images, setImages] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageAssociation, setImageAssociation] = useState<ImageAssociation>(
    {}
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const onAdd = () => {
    inputRef.current?.click();
  };

  const onFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const actualFiles: File[] = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        actualFiles.push(files.item(i) as File);
      }
    }
    if (props.allowMultiple) {
      setImages([...images, ...actualFiles]);
    }
    else {
      setImages([...actualFiles]);
    }

  };

  const promisifyReader = (img: File) => {
    return new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      fileReader.readAsDataURL(img);
    });
  };

  const onImageRemove = (imgUrl: string) => {
    const urls = [...imageUrls];
    const index = urls.findIndex((i) => i === imgUrl);
    urls.splice(index, 1);
    setImageUrls(urls);

    const files = [...images];
    const associated = imageAssociation[imgUrl];
    const fileIndex = images.findIndex((i) => i === associated);
    files.splice(fileIndex, 1);
    setImages(files);
  };

  const readFile = (file: File) => {
    const reader = new FileReader()
    return new Promise((resolve) => {
      reader.onload = (e) => {
        resolve(e.target?.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const readImage = (dataUrl: string) => {
    const img = new Image()
    return new Promise<HTMLImageElement>((resolve) => {
      img.onload = () => {
        resolve(img)
      }
      img.src = dataUrl
    })
  }

  const canvasToBlob = (canvas: HTMLCanvasElement) => {
    return new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob as Blob)
      }, 'image/jpeg', 0.92)
    })
  }

  const onImageRotate = async (imgUrl: string) => {
    const associated = imageAssociation[imgUrl];
    const file = images.find((i) => i === associated) as File

    const dataUrl = await readFile(file) as string
    const img = await readImage(dataUrl)

    const degrees = 90 % 360
    const radians = degrees * (Math.PI / 180)

    const sin = Math.abs(Math.sin(radians))
    const cos = Math.abs(Math.cos(radians))
    const width = img.width
    const height = img.height

    const newWidth = Math.round(width * cos + height * sin);
    const newHeight = Math.round(width * sin + height * cos);

    const canvas = document.createElement('canvas')
    canvas.width = newWidth
    canvas.height = newHeight

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    ctx.translate(newWidth / 2, newHeight / 2)
    ctx.rotate(radians)
    ctx.drawImage(img, -width / 2, -height / 2);

    const blob = await canvasToBlob(canvas)
    const updatedFile = new File([blob], file.name);

    const files = [...images];
    const fileIndex = images.findIndex((i) => i === associated);
    files[fileIndex] = updatedFile
    setImages(files);
  }

  useEffect(() => {
    const fetchData = async () => {
      const loaded: string[] = [];
      const associations: ImageAssociation = {};
      for (let i = 0; i < images.length; i++) {
        const current = images[i];
        const res = await promisifyReader(current);
        loaded.push(res);
        associations[res] = current;
      }
      setImageAssociation(associations);
      setImageUrls(loaded);
      props.onChange(associations);
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    const fetchData = async () => {
      const associations = { ...imageAssociation }
      const loaded = [...imageUrls]
      let fetchedImages = [] as File[];
      if (props.allowMultiple) {
        const imageLinks: string[] = props.value as any;
        for (let i = 0; i < imageLinks.length; i++) {
          const link = `${process.env.REACT_APP_API}/${imageLinks[i]}`;
          if (!associations[link]) {
            const response = await fetch(link);
            const data = await response.blob();
            const file = new File([data], link);
            associations[link] = file;
            loaded.push(link);
            fetchedImages.push(file);
          }
        }
      }
      else {
        const imageLink: string = props.value as any;
        const link = `${process.env.REACT_APP_API}/${imageLink}`;
        if (!associations[link]) {
          const response = await fetch(link);
          const data = await response.blob();
          const file = new File([data], link);
          associations[link] = file;
          loaded.push(link);
          fetchedImages.push(file);
        }
      }
      setImages(fetchedImages);
      setImageAssociation(associations);
      setImageUrls(loaded);
    }

    if (props.value)
      fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <Flex width="100%" direction="column" marginTop="20px" marginBottom="40px">
      <Input
        type="file"
        multiple={props.allowMultiple}
        hidden
        ref={inputRef}
        onChange={onFileSelected}
      />
      <Button
        //maxWidth="200px"
        marginLeft="auto"
        marginRight="auto"
        onClick={onAdd}
      >
        {props.buttonText}
      </Button>
      <Flex width="100%" direction="row" wrap="wrap" marginTop="10px">
        {imageUrls.map((img, index) => (
          <div
            key={`${img}-${index}-container`}
            style={{
              position: "relative",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "5px",
            }}
          >
            <img
              key={`${img}-${index}-img`}
              src={img}
              alt="MISSING"
              height="300px"
              width="300px"
              style={{ display: "block", borderRadius: "5px" }}
            />
            <IconButton
              onClick={() => onImageRemove(img)}
              aria-label="close"
              backgroundColor="red.500"
              icon={<CloseIcon />}
              position="absolute"
              top="0"
              right="0"
              key={`${img}-${index}-button`}
            />
            <IconButton
              onClick={() => onImageRotate(img)}
              aria-label="close"
              icon={<MdCropRotate />}
              position="absolute"
              top="0"
              left="0"
              key={`${img}-${index}-button`}
            />
          </div>
        ))}
      </Flex>
    </Flex>
  );
}
