import { useAuth, User } from "oidc-react";
import { ReactElement, useEffect } from "react";
import { useRecoilState } from "recoil";
import { $auth } from "./atoms";

interface Props {
  children?: ReactElement,
  fallback: ReactElement
}

export function AuthSync({ children }: Props) {
  const auth = useAuth();
  const [, setAuthCtx] = useRecoilState($auth);

  useEffect(() => {
    setAuthCtx(auth.userData as User);
  }, [auth, setAuthCtx])

  if (!auth || auth.isLoading)
    return (<></>);
  else
    return (<>{children}</>);
}