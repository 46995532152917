import { Skeleton } from "@chakra-ui/react";
import { Suspense } from "react";
import { HeroSection, LatestCars } from "../../components";

export function Home() {
    return (
        <>
            <HeroSection />
            <Suspense fallback={<Skeleton />}>
                <LatestCars />
            </Suspense>
        </>
    )
}