import { Link, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";

export function NavLink({ children }: { children: ReactNode }) {
  return (
    <Link
      px={2}
      py={1}
      as="ul"
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
    >
      {children}
    </Link>
  );
}
