import { Divider, HStack, Text } from "@chakra-ui/react";
import { Car } from "../../models";
import { SelectedParts } from "../selected-parts";
import "./index.css";
import { BsBook } from "react-icons/bs";

interface Props {
  car: Car
}

export function CarDescription(props: Props) {
  return (
    <div style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto", width: "100%" }}    >
      <Divider></Divider>
      <SelectedParts carId={props.car.id} />
      <Divider marginTop="5px"></Divider>
      <HStack>
        <BsBook />
        <Text fontSize={20} fontWeight="bold">Descrizione dal venditore</Text>
      </HStack>
      <section>
        {props.car.description}
      </section>
    </div>
  );
}
