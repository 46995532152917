import { useNavigate } from "react-router-dom";
import { Flex, Heading, PAGE_WIDTH, PageContainer } from "../../components";
import { ApproveGrid } from "./ApproveGrid";
import { useCarsToApprove } from "../../state/approval";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ModelsAndManufacturersPage } from "./ModelsAndManufacturersPage";
import { CarPartsPage } from "./CarPartsPage";
import { FeedbacksSummaryPage } from "./Feedbacks";
import { useFeedbacks } from "../../state/feedbacks";
import { FaqAdminPage } from "./FaqAdminPage";

export function AdminSection() {
  const filterWidth = 0;
  const gridWidth = PAGE_WIDTH - filterWidth;
  const navigate = useNavigate();
  const cars = useCarsToApprove();
  const feedbacks = useFeedbacks();

  const onCardClick = (id: string) => {
    navigate('/listing-detail/' + id)
  };

  return (
    <PageContainer>
      <Tabs>
        <TabList>
          <Tab>Auto da approvare ({cars.carList.length})</Tab>
          <Tab>Gestione marche e modelli</Tab>
          <Tab>Gestione parti</Tab>
          <Tab>Feedbacks ({feedbacks.length})</Tab>
          <Tab>FAQ</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction="row" wrap="wrap">
              <Heading hidden={cars.carList.length > 0}>Nessuna auto da approvare.</Heading>
              <ApproveGrid
                cars={cars.carList}
                onCarSelected={onCardClick}
                gridWidth={gridWidth}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <ModelsAndManufacturersPage />
          </TabPanel>
          <TabPanel>
            <CarPartsPage />
          </TabPanel>
          <TabPanel>
            <FeedbacksSummaryPage feedbacks={feedbacks} />
          </TabPanel>
          <TabPanel>
            <FaqAdminPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  )
}

