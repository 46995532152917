import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useColorModeValue, Text, Icon, Link as ChakraLink, Divider } from "@chakra-ui/react";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box, Flex,
  HStack,
  IconButton, Stack, useDisclosure
} from "../base";
import { Logo } from "../logo";
import { LinkDefintion } from "./link";
import { NavLink } from "./NavLink";
import { UserProfile } from "../user-profile";
import { FaDonate } from "react-icons/fa";

const DefaultLinks: LinkDefintion[] = [
  { label: "Home", url: "/" },
  //{ label: "Tutte le auto", url: "/car-list" },
];

export function Navbar() {
  const auth = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [links, setLinks] = useState<LinkDefintion[]>(DefaultLinks);
  const modalProps = useDisclosure();

  const onLogoClick = () => { };

  useEffect(() => {
    const linksToSet = [...DefaultLinks];
    if (auth && auth.userData) {
      linksToSet.push({ label: "I tuoi annunci", url: "/listings" });
      linksToSet.push({ label: "Preferiti", url: "/favorites" });
    }
    if (auth && auth.userData && auth.userData.profile.preferred_username === 'info@partscout.it')
      linksToSet.push({ label: 'Admin Section', url: '/admin-section' });
    setLinks(linksToSet);
  }, [auth]);

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4} position="fixed" top={0} width="100%" overflow="hidden" color={useColorModeValue("gray.700", "gray.200")} zIndex={2}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems="center" height="100%">
          <Logo h={40} w={100} onClick={onLogoClick} />
          <HStack as="nav" spacing={4} display={{ base: "none", md: "flex" }} height="100%" alignItems="center">
            {links.map((link, index) => (
              <>
                <NavLink key={link.url}>
                  <Link to={link.url}>{link.label}</Link>
                </NavLink>
                {index < links.length - 1 && <Divider orientation="vertical" height="60%" borderColor="gray.400" />}
              </>
            ))}
          </HStack>
        </HStack>
        <ChakraLink href="https://ko-fi.com/partscout" isExternal display={{ base: "none", md: "flex" }}>
          <HStack cursor="pointer" borderRadius="xl" paddingLeft="10px" paddingRight="10px" backgroundColor="green.300" >
            <Text>Ti piace il sito e vuoi fare una donazione? Clicca qui!</Text>
            <Icon as={FaDonate} />
          </HStack>
        </ChakraLink>
        <Flex alignItems={"center"}>
          {!auth ||
            (!auth.userData && (
              <p style={{ cursor: 'pointer' }} onClick={() => auth.signIn()}>Accedi/Registrati</p>
            ))}
          {auth && auth.userData && (
            <>
              <p style={{ cursor: 'pointer' }} onClick={() => modalProps.onOpen()}>Profilo</p>
              <p style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => auth.signOutRedirect()}>Logout</p>
              <UserProfile isOpen={modalProps.isOpen} onOpen={modalProps.onOpen} onClose={modalProps.onClose} />
            </>
          )}
        </Flex>
      </Flex>

      {
        isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {links.map((link) => (
                <NavLink key={link.url}>
                  <Link to={link.url}>{link.label}</Link>
                </NavLink>
              ))}
              <ChakraLink href="https://ko-fi.com/partscout" isExternal>
                <HStack cursor="pointer" borderRadius="xl" paddingLeft="10px" paddingRight="10px" marginTop="10px" backgroundColor="green.300" >
                  <Text>Ti piace il sito e vuoi fare una donazione? Clicca qui!</Text>
                  <Icon as={FaDonate} />
                </HStack>
              </ChakraLink>
            </Stack>
          </Box>
        ) : null
      }
    </Box >
  );
}
