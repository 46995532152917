import { useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Button, VisuallyHidden } from "../base";

interface Props {
  children: ReactNode;
  label: string;
  href: string;
}

export function SocialButton(props: Props) {
  return (
    <Button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={props.href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      onClick={(e) => { e.preventDefault(); window.open(props.href, '_blank'); }}
    >
      <VisuallyHidden>{props.label}</VisuallyHidden>
      {props.children}
    </Button>
  );
}
