interface Props {
    dayOfWeek?: number;
}

export function DayOfWeek(props: Props) {
    switch (props.dayOfWeek) {
        case 1:
            return(<td>Lunedì</td>)
        case 2:
            return(<td>Martedì</td>)
        case 3:
            return(<td>Mercoledì</td>)
        case 4:
            return(<td>Giovedì</td>)
        case 5:
            return(<td>Venerdì</td>)
        case 6:
            return(<td>Sabato</td>)
        case 7:
            return(<td>Domenica</td>)
        default:
            return(<td>Che dio caro di giorno hai?</td>)
    }
}