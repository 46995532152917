import { Suspense, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FilterPanel, Flex, PageContainer, Pagination } from "../../components";
import { CarGridSkeleton } from "../../components/car-grid-skeleton";
import { FilterSkeleton } from "../../components/filter-panel/FilterSkeleton";
import { Filters } from "../../models";
import { CarGrid } from "./CarGrid";
import { useCarList } from "../../state/cars";
import { Helmet } from "react-helmet";

export function CarList() {
  const filterWidth = 350;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<Filters>({
    vendorId: null,
    selectedManufacturer: searchParams.get("selectedManufacturer"),
    selectedModel: searchParams.get("selectedModel"),
    searchCode: searchParams.get("searchCode"),
    yearMin: searchParams.get("yearMin"),
    yearMax: searchParams.get("yearMax"),
    priceMin: searchParams.get("priceMin"),
    priceMax: searchParams.get("priceMax"),
    partsToSearch: searchParams.getAll("partsToSearch"),
    page: Number.parseInt(searchParams.get("page") ?? "1")
  });

  const carList = useCarList(filters);

  const onApplyFilter = (manufacturer: string | null, model: string | null, searchCode: string | null, yearMin: string | null, yearMax: string | null, priceMin: string | null, priceMax: string | null, partsToSearch: string[] | null) => {
    const newFilters: any = {
      ...filters,
      selectedManufacturer: manufacturer,
      selectedModel: model,
      searchCode: searchCode,
      yearMin: yearMin,
      yearMax: yearMax,
      priceMin: priceMin,
      priceMax: priceMax,
      partsToSearch: partsToSearch,
    };

    setFilters({
      ...newFilters,
      page: 1
    });

    const qs = new URLSearchParams()
    Object.keys(newFilters).forEach(k => {
      const current = newFilters[k]
      if (current) {
        if (Array.isArray(current)) {
          if (current.length && current.length > 0) {
            current.forEach(c => {
              qs.append(k, c)
            })
          }
        } else {
          qs.append(k, current)
        }
      }
    })
    window.location.replace(`${process.env.REACT_APP_CLIENT}/car-list?${qs.toString()}`)
  }

  const onPageChange = (page: number) => {
    setFilters({
      ...filters,
      page: page
    });

    const newFilters: any = {
      ...filters,
      page: page
    };

    const qs = new URLSearchParams()
    Object.keys(newFilters).forEach(k => {
      const current = newFilters[k]
      if (current) {
        if (Array.isArray(current)) {
          if (current.length && current.length > 0) {
            current.forEach(c => {
              qs.append(k, c)
            })
          }
        } else {
          qs.append(k, current)
        }
      }
    })
    window.location.replace(`${process.env.REACT_APP_CLIENT}/car-list?${qs.toString()}`)
  }

  const onCardClick = (id: string) => {
    navigate(`/car-detail/${id}`);
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Part Scout | Car List</title>
        <meta name="description" content="PartScout a site for all your car rebuild needs" />
        <meta property="og:title" content="Part Scout | Car List" />
        <meta property="og:description" content="PartScout a site for all your car rebuild needs" />
        <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
      </Helmet>
      <Flex
        flexDirection="column"
        paddingLeft="10px"
        paddingRight="10px"
        marginTop="10px"
        marginBottom="10px"
        alignItems="center"
        gap={4}
      >
        <Suspense fallback={<FilterSkeleton />}>
          <FilterPanel
            manufacturer={filters.selectedManufacturer}
            model={filters.selectedModel}
            searchCode={filters.searchCode}
            yearMin={filters.yearMin ?? null}
            yearMax={filters.yearMax ?? null}
            priceMin={filters.priceMin ?? null}
            priceMax={filters.priceMax ?? null}
            partsToSearch={filters.partsToSearch ?? null}
            onApply={onApplyFilter}
          />
        </Suspense>
        <Suspense fallback={<CarGridSkeleton width={filterWidth} />}>
          <CarGrid
            onCarSelected={onCardClick}
            currentFilters={filters}
            carList={carList}
          />
          <Pagination total={carList.pagination.totalPages} current={carList.pagination.currentPage} onPageChange={onPageChange} />
        </Suspense>
      </Flex>
    </PageContainer>
  );
}
