import { CarCard, Flex } from "../../components";
import { CarList, Filters } from "../../models";
import { Text } from "@chakra-ui/react";

interface Props {
  onCarSelected: (id: string) => void,
  currentFilters: Filters,
  carList: CarList
}

export function CarGrid(props: Props) {

  const onCarSelected = (id: string) => {
    props.onCarSelected(id);
  }

  return (
    <Flex
      flexWrap="wrap"
      gap={5}
      justifyContent="center"
      alignItems="center"
      maxWidth="1000px"
    >
      {props.carList.carList.length === 0 ? (
        <Text fontSize="xl">Nessun risultato corrispondente alla tua ricerca</Text>
      ) : (
        props.carList.carList.map((c) => (
          <CarCard key={c.id} onClick={onCarSelected} car={c} />
        ))
      )}
    </Flex>
  )
}