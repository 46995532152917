import { FaFacebook } from "react-icons/fa";
import { HStack, Hide, Icon, Show, useColorModeValue } from "@chakra-ui/react";
import { Box, Container, Stack, Text, useDisclosure } from "../base";
import { Logo } from "../logo";
import { SocialButton } from "./SocialButton";
import { Link } from "react-router-dom";
import { BsLightbulb } from "react-icons/bs";
import { InsertFeedbackPage } from "../feedback";
import { FaqPage } from "../faq";
import { QuestionIcon } from "@chakra-ui/icons";

export function Footer() {
  const feedbackModalProps = useDisclosure();
  const faqModalProps = useDisclosure();
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      position="fixed"
      bottom={0}
      width="100%"
      overflow="hidden"
      zIndex={2}
    >
      <FaqPage isOpen={faqModalProps.isOpen} onClose={faqModalProps.onClose} />
      <InsertFeedbackPage isOpen={feedbackModalProps.isOpen} onOpen={feedbackModalProps.onOpen} onClose={feedbackModalProps.onClose} />
      <Container
        as={Stack}
        width="100%"
        py={4}
        paddingLeft={'20px'}
        paddingRight={'20px'}
        direction={{ base: "row", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Logo h={40} w={100} />
        <Hide below="md">
          <Text>
            2024 PartScout. All rights reserved&nbsp;
            <Link style={{ textDecoration: 'underline' }} to={'/privacy-policy'}>(Privacy Policy)</Link>
          </Text>
          <Stack direction={"row"} spacing={6}>
            <HStack cursor="pointer" borderRadius="xl" paddingLeft="10px" paddingRight="10px" backgroundColor="yellow.300" onClick={() => feedbackModalProps.onOpen()}>
              <Text>Hai delle idee o dei feedback? Scrivici!</Text>
              <Icon as={BsLightbulb} />
            </HStack>

            <Text cursor="pointer" onClick={() => faqModalProps.onOpen()} style={{ textDecoration: 'underline' }}>Faq</Text>
            <SocialButton label={"Facebook"} href={"https://www.facebook.com/PartScout"}  >
              <FaFacebook />
            </SocialButton>
          </Stack>
        </Hide>
        <Show below="md">
          <Stack direction={"row"} spacing={6} alignItems={"center"}>
            <HStack cursor="pointer" borderRadius="xl" paddingLeft="10px" paddingRight="10px" backgroundColor="yellow.300" onClick={() => feedbackModalProps.onOpen()}>
              <Text>Scrivici il tuo feedback!</Text>
              <Icon as={BsLightbulb} />
            </HStack>
            <Icon as={QuestionIcon} cursor="pointer" onClick={() => faqModalProps.onOpen()} alignContent={"center"} />
            <SocialButton label={"Facebook"} href={"https://www.facebook.com/PartScout"}  >
              <FaFacebook />
            </SocialButton>
          </Stack>
        </Show>
      </Container>
    </Box>
  );
}
