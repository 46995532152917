import { selector } from "recoil";
import { $auth } from "./atoms";
import { User } from "../../models/user";

export const $token = selector<string>({
  key: "AccessToken",
  get: ({ get }) => {
    const user = get($auth);
    return user?.access_token as string;
  },
});

export const $user = selector<User | null>({
  key: "CurrentUser",
  get: ({ get }) => {
    const user = get($auth);
    if (user && user.profile) {
      return {
        name: user?.profile.name as string,
        userName: user?.profile.preferred_username as string
      }
    }
    return null;
  },
});

