import { Flex, Heading, ErrorToast, SuccessFullToast } from "../../components";
import { GUID_EMPTY, fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";
import { Button, Checkbox, Divider, Input, InputGroup, InputLeftAddon, Stack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { CarPart } from "../../models";
import { useCarParts } from "../../state/car-parts";
import { Select } from "chakra-react-select";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { $carParts } from "../../state/car-parts";


export function CarPartsPage() {
    const toast = useToast();
    const token = useAccessToken();
    const allParts = useCarParts();
    const [newPart, setNewPart] = useState<CarPart>({ id: GUID_EMPTY, name: "", isSummaryItem: false, position: "" });
    const [partToUpdate, setPartToUpdate] = useState<CarPart>({ id: GUID_EMPTY, name: "", isSummaryItem: false, position: "" });
    const [partToDelete, setPartToDelete] = useState<CarPart>({ id: GUID_EMPTY, name: "", isSummaryItem: false, position: "" });
    const cacheInvalidate = useRecoilRefresher_UNSTABLE($carParts);

    const clearPartsCache = async () => {
        let url = `${process.env.REACT_APP_API}/api/admin/clearPartsCache`
        const res = await fetchWithAuth(url, token, {
            method: 'PATCH'
        })
        if (res.status === 200) {
            SuccessFullToast(toast, "Cache svuotata", "Cache svuotata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    };

    const updateNewPart = (propName: string, value: string) => {
        const updatedPart: any = { ...newPart }
        updatedPart[propName] = value
        setNewPart(updatedPart)
    }

    const updatePartToUpdate = (propName: string, value: string) => {
        const updatedPart: any = { ...partToUpdate }
        updatedPart[propName] = value
        setPartToUpdate(updatedPart)
    }

    const isPartAlreadyPresent = (partName: string) => {
        if (partName !== "") {
            if (allParts.filter(p => p.name.toUpperCase() === partName.toUpperCase()).length > 0) {
                return true;
            }
        }
        return false;
    }

    const addNewPart = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/addNewCarPart`
        const data = new FormData();
        data.append('payload', JSON.stringify(newPart));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })

        if (res.status === 200) {
            SuccessFullToast(toast, "Parte aggiunta", "Parte aggiunta con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const editPart = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/editCarPart`
        const data = new FormData();
        data.append('payload', JSON.stringify(partToUpdate));
        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data
        })

        if (res.status === 200) {
            SuccessFullToast(toast, "Parte modificata", "Parte modificata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    const deletePart = async () => {
        let url = `${process.env.REACT_APP_API}/api/Admin/deleteCarPart`
        const data = new FormData();
        data.append('payload', JSON.stringify(partToDelete));
        const res = await fetchWithAuth(url, token, {
            method: 'DELETE',
            body: data
        })

        if (res.status === 200) {
            SuccessFullToast(toast, "Parte eliminata", "Parte eliminata con successo.");
        }
        else {
            ErrorToast(toast, "ERRORE", "QUALCOSA E' ANDATO STORTO, CONTROLLARE I LOG.");
        }
        cacheInvalidate();
    }

    return (
        <Flex direction="row" wrap="wrap" gap={3} >
            <Button onClick={clearPartsCache}>Svuota cache pezzi</Button>
            <Divider />

            <Heading >CREAZIONE NUOVA PARTE:</Heading>
            <InputGroup size="lg">
                <InputLeftAddon children="Posizione:" />
                <Input placeholder="Posizione della nuova parte" size="lg" type="text" value={newPart.position} onChange={(e: any) => { updateNewPart("position", e.target.value) }} />
            </InputGroup>
            <InputGroup size="lg">
                <InputLeftAddon children="Nome:" />
                <Input placeholder="Nuovo nome parte" size="lg" type="text" isInvalid={isPartAlreadyPresent(newPart.name)} value={newPart.name} onChange={(e: any) => { updateNewPart("name", e.target.value) }} />
                <Checkbox marginLeft="20px" isChecked={newPart.isSummaryItem} onChange={(e: any) => { updateNewPart("isSummaryItem", e.target.checked) }}> E' la parte comprensiva di tutta la posizione </Checkbox>
            </InputGroup>
            <Button onClick={addNewPart} isDisabled={newPart.name === "" || newPart.position === "" || isPartAlreadyPresent(newPart.name)}>Crea nuova parte</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >MODIFICA PARTE ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona parte a cui cambiare i campi"
                    options={allParts.map(p => ({ label: p.position + " - " + p.name, value: p.id }))}
                    onChange={(e: any) => { let newValue = allParts.find(p => (p.id === e.value)); if (newValue !== undefined) setPartToUpdate(newValue) }}
                />
            </Stack>
            <InputGroup size="lg">
                <InputLeftAddon children="Nuova posizione:" />
                <Input placeholder="Nuova posizione" size="lg" type="text" value={partToUpdate.position} onChange={(e: any) => { updatePartToUpdate("position", e.target.value) }} />
            </InputGroup>
            <InputGroup size="lg">
                <InputLeftAddon children="Nome:" />
                <Input placeholder="Nuova parte" size="lg" type="text" isInvalid={isPartAlreadyPresent(partToUpdate.name)} value={partToUpdate.name} onChange={(e: any) => { updatePartToUpdate("name", e.target.value) }} />
                <Checkbox marginLeft="20px" isChecked={partToUpdate.isSummaryItem} onChange={(e: any) => { updatePartToUpdate("isSummaryItem", e.target.checked) }}> E' la parte comprensiva di tutta la posizione </Checkbox>
            </InputGroup>
            <Button onClick={editPart} isDisabled={partToUpdate.name === "" || partToUpdate.position === "" || isPartAlreadyPresent(partToUpdate.name)}>Aggiorna parte</Button>
            <Divider />

            <Stack paddingBottom="10px">
                <Heading >ELIMINAZIONE PARTE ESISTENTE:</Heading>
                <Select size="lg"
                    placeholder="Seleziona parte da eliminare"
                    options={allParts.map(p => ({ label: p.position + " - " + p.name, value: p.id }))}
                    onChange={(e: any) => { let newValue = allParts.find(p => (p.id === e.value)); if (newValue !== undefined) setPartToDelete(newValue) }}
                />
                <Button onClick={deletePart} isDisabled={partToDelete.name === "" || partToDelete.position === ""} colorScheme="red">Elimina parte</Button>
            </Stack>
        </Flex>
    );
}