import { Input } from "../base";
import { useCallback, useEffect, useRef } from "react";

interface Props {
    value: string
    isInvalid: boolean
    onChange: (value: string) => void
}

export function BingAutosuggest(props: Props) {

    const manager = useRef(null)
    const inputEl = useRef<HTMLInputElement>(null)

    const onSuggestionSelected = useCallback((suggestion: any) => {
        props.onChange(suggestion.formattedSuggestion);
    }, [props]);

    const handleInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if (inputValue === '') {
            props.onChange("");
        }
        // eslint-disable-next-line
    }, []);

    const makeAutoSuggest = useCallback(() => {
        const windowObj = window as any
        const { Maps } = windowObj.Microsoft
        if (!manager.current) {
            Maps.loadModule('Microsoft.Maps.AutoSuggest', () => {
                const mng = new Maps.AutosuggestManager({
                    maxResults: 4,
                    countryCode: "IT"
                })
                mng.attachAutosuggest('#searchBox', '#searchBoxContainer', onSuggestionSelected)
                manager.current = mng
            })
        }
    }, [onSuggestionSelected])

    useEffect(() => {
        const windowObj = window as any
        windowObj.makeAutoSuggest = makeAutoSuggest
        if (windowObj.Microsoft && windowObj.Microsoft.Maps) {
            makeAutoSuggest()
        }
    }, [makeAutoSuggest])

    useEffect(() => {
        const el = inputEl.current as HTMLInputElement
        el.value = props.value ?? ''
    }, [props.value])

    return (
        <div id='searchBoxContainer' style={{ marginRight: "30px" }}>
            <Input
                id='searchBox'
                placeholder="Inserire comune/indirizzo qui..."
                size="lg"
                isInvalid={props.isInvalid}
                ref={inputEl}
                onChange={handleInput}
            />
        </div>
    )
}