
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AvailableParts, Button, ErrorToast, FileUpload, ImageAssociation, Input, PageContainer, Select, SimpleGrid, SuccessFullToast, Textarea } from "../../components";
import { Listing } from "../../models";
import { useAccessToken, useUser } from "../../state/auth";
import { useModelAndManufacturers } from "../../state/model-manufacturers";
import { fetchWithAuth } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { $listings } from "../../state/listings";
import { $carsToApprove } from "../../state/approval";
import { Divider, Flex, InputGroup, InputLeftAddon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useToast } from "@chakra-ui/react";

export function ListingDetail() {
    const { id } = useParams();
    const token = useAccessToken();
    const navigate = useNavigate();
    const user = useUser();
    const toast = useToast();
    const manufacturersAndModels = useModelAndManufacturers();
    const [listing, setListing] = useState<Listing>({ year: 2000 });
    const listingIvalidate = useRecoilRefresher_UNSTABLE($listings);
    const toApproveInvalidate = useRecoilRefresher_UNSTABLE($carsToApprove);
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const onPropertyChange = async (propName: string, value: any) => {
        const updatedListing: any = { ...listing }
        updatedListing[propName] = value
        setListing(updatedListing)
    }

    const onSave = async () => {
        setIsSaving(true);
        let url = `${process.env.REACT_APP_API}/api/listing/create`
        if (id !== 'create')
            url = `${process.env.REACT_APP_API}/api/listing/edit`

        const data = new FormData();
        const payload: any = { ...listing };
        const files = payload['files'] as ImageAssociation;
        delete payload['files'];

        data.append('payload', JSON.stringify(payload));
        Object.keys(files).forEach((k, i) => {
            data.append(`${i}`, files[k]);
        });

        const res = await fetchWithAuth(url, token, {
            method: 'POST',
            body: data,
        })
        setIsSaving(false);
        if (res.status === 200) {
            listingIvalidate()
            SuccessFullToast(toast, "Annuncio salvato", "Annuncio salvato correttamente.")
            navigate('/listings')
        }
        else {
            ErrorToast(toast, "Annuncio NON salvato")
        }
    }

    const onLogicalDelete = async () => {
        let url = `${process.env.REACT_APP_API}/api/listing/logicalDelete/${id}`

        const res = await fetchWithAuth(url, token, { method: 'DELETE' })

        if (res.status === 200) {
            listingIvalidate()
            SuccessFullToast(toast, "Annuncio eliminato", "Annuncio eliminato correttamente.")
            navigate('/listings')
        }
        else {
            ErrorToast(toast, "Annuncio NON eliminato", "C'è stato un problema durante la cancellazione.")
        }
    }

    const onApprove = async () => {
        const url = `${process.env.REACT_APP_API}/approval/ApproveCarByID/${id}`
        const res = await fetchWithAuth(url, token, { method: 'POST' });
        if (res.status === 200) {
            toApproveInvalidate()
            navigate('/admin-section')
        }
    }

    const onReject = async () => {
        const url = `${process.env.REACT_APP_API}/approval/DeleteCarByID/${id}`
        const res = await fetchWithAuth(url, token, { method: 'DELETE' });
        if (res.status === 200) {
            toApproveInvalidate();
            navigate('/admin-section')
        }
    }

    useEffect(() => {
        const fetch = async () => {
            if (id !== 'create') {
                const url = `${process.env.REACT_APP_API}/api/listing/${id}`;
                const respone = await fetchWithAuth(url, token, { method: 'GET' })
                const res = await respone.json()
                setListing(res)
            }
        }
        fetch().catch(console.error)
    }, [id, token])

    const saveIsDisabled = () => {
        if (listing.selectedManufacturer === undefined ||
            listing.selectedManufacturer === null ||
            listing.selectedManufacturer === "" ||
            listing.selectedModel === undefined ||
            listing.selectedModel === null ||
            listing.selectedModel === "" ||
            listing.carTitle === undefined ||
            listing.carTitle === null ||
            listing.carTitle === "" ||
            (listing.carPartsWithPrice !== undefined && listing.carPartsWithPrice?.findIndex(c => c.price === undefined || c.price === null || c.price === "") !== -1)) {
            return true;
        }
        return false;
    }

    return (
        <PageContainer>
            <Flex width="100%" justifyContent="center" marginBottom="10px" gap={5} flexWrap={{ base: "wrap", md: "nowrap" }}>
                <Button
                    isLoading={isSaving}
                    loadingText='Salvataggio...'
                    width="100%"
                    colorScheme="blue"
                    size="lg"
                    type="submit"
                    onClick={onSave}
                    isDisabled={saveIsDisabled()}
                >Salva</Button>
                {id !== 'create' &&
                    <Button
                        width="100%"
                        colorScheme="red"
                        size="lg"
                        type="submit"
                        onClick={onLogicalDelete}
                    >Elimina annuncio</Button>
                }
                {user?.userName === 'info@partscout.it' && id !== 'create' &&
                    <Button
                        width="100%"
                        colorScheme="green"
                        size="lg"
                        type="submit"
                        onClick={onApprove}
                    >Approva</Button>
                }
                {user?.userName === 'info@partscout.it' && id !== 'create' &&
                    <Button
                        width="100%"
                        colorScheme="red"
                        size="lg"
                        type="submit"
                        onClick={onReject}
                    >Rigetta</Button>
                }
            </Flex>
            <SimpleGrid minChildWidth="350px" spacing="20px">
                <InputGroup size="lg">
                    <InputLeftAddon children="Marca: " />
                    <Select
                        placeholder='Marca'
                        value={listing.selectedManufacturer as string}
                        isInvalid={listing.selectedManufacturer === undefined || listing.selectedManufacturer === null || listing.selectedManufacturer === ''}
                        onChange={(e: any) => { onPropertyChange('selectedManufacturer', e.target.value) }}
                        size="lg"
                        color="brand.900">
                        {Object.keys(manufacturersAndModels).map((key) => (
                            <option key={manufacturersAndModels[key][0].manufacturerId} value={manufacturersAndModels[key][0].manufacturerId}>
                                {manufacturersAndModels[key][0].manufacturerName}
                            </option>
                        ))}
                    </Select>
                </InputGroup>
                <InputGroup size="lg">
                    <InputLeftAddon children="Modello: " />
                    <Select
                        placeholder='Modello'
                        size="lg"
                        value={listing.selectedModel as string}
                        isInvalid={listing.selectedModel === undefined || listing.selectedModel === null || listing.selectedModel === ''}
                        onChange={(e: any) => { onPropertyChange('selectedModel', e.target.value) }}
                        color="brand.900">
                        {listing.selectedManufacturer && manufacturersAndModels[listing.selectedManufacturer].map((m) => (
                            <option key={m.modelId} value={m.modelId}>
                                {m.modelName}
                            </option>
                        ))}
                    </Select>
                </InputGroup>
                <InputGroup size="lg">
                    <InputLeftAddon children="Titolo: " />
                    <Input placeholder="Titolo dell'annuncio" isInvalid={listing.carTitle === undefined || listing.carTitle === null || listing.carTitle === ''} value={listing.carTitle ?? ''} onChange={(e: any) => { onPropertyChange('carTitle', e.target.value) }} />
                </InputGroup>
                <InputGroup size="lg" >
                    <InputLeftAddon children="Anno: " />
                    <NumberInput size="lg" min={1900} max={2024} value={listing.year} width="100%" onChange={(e: any) => { onPropertyChange('year', e) }}>
                        <NumberInputField placeholder="Anno" /><NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </InputGroup>
                <InputGroup size="lg">
                    <InputLeftAddon children="Prezzo: " />
                    <Input placeholder="Prezzo" size="lg" type="number" value={listing.price ?? ''} onChange={(e: any) => { onPropertyChange('price', e.target.value) }} />
                </InputGroup>
                <InputGroup size="lg">
                    <InputLeftAddon children="Km: " />
                    <Input placeholder="Km" size="lg" type="number" value={listing.km ?? ''} onChange={(e: any) => { onPropertyChange('km', e.target.value) }} />
                </InputGroup>
            </SimpleGrid>
            <AvailableParts value={listing.carPartsWithPrice ?? []} onChange={(e: any) => { onPropertyChange('carPartsWithPrice', e) }} />
            <label>Descrizione:</label>
            <Divider />
            <Textarea placeholder='Ulteriore descrizione' value={listing.description ?? ''} onChange={(e: any) => { onPropertyChange('description', e.target.value) }} marginTop={'20px'} />
            <FileUpload value={listing.imageLinks} allowMultiple={true} buttonText="Seleziona foto" onChange={(e) => { onPropertyChange('files', e); }} />
        </PageContainer>
    )

}