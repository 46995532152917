
import { useNavigate } from "react-router-dom";
import { Button, Flex, Heading, InformationToast, PAGE_WIDTH, PageContainer, UserProfile } from "../../components";
import { ListingGrid } from "./ListingGrid";
import { useListings } from "../../state/listings";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { fetchWithAuth } from "../../utils";
import { useAccessToken } from "../../state/auth";

export function Listings() {
  const filterWidth = 350;
  const gridWidth = PAGE_WIDTH - filterWidth;
  const navigate = useNavigate();
  const listings = useListings();
  const approved = listings.carList.filter(c => c.approved)
  const toApprove = listings.carList.filter(c => !c.approved)
  const modalProps = useDisclosure();
  const toast = useToast();
  const token = useAccessToken();


  const userHaveProfileInfo = async () => {
    const url = `${process.env.REACT_APP_API}/api/profileinfo/userHaveProfileInfo`;
    const response = await fetchWithAuth(url, token, { method: 'GET' })
    const res = await response.json()
    return res;
  }

  const onCardClick = (id: string) => {
    navigate('/listing-detail/' + id)
  };

  const onCreate = async () => {
    if (listings.carList.length > 0 || await userHaveProfileInfo()) { navigate('/listing-detail/create') }
    else {
      InformationToast(toast, "Informazioni profilo mancanti", "Prima di poter inserire un annuncio compila le informazioni di profilo mancanti");
      modalProps.onOpen();
    }
  }

  return (
    <PageContainer>
      <Flex flexWrap="wrap" gap={5} justifyContent="center" alignItems="center" direction="column">
        <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "10px",
          }}
          width="200px"
          colorScheme="blue"
          size="lg"
          type="submit"
          onClick={() => {
            onCreate();
          }}
        >
          Crea
        </Button>
        <div>
          {toApprove.length > 0 &&
            <>
              <Heading textAlign="center">In attesa di approvazione</Heading>
              <ListingGrid
                listings={toApprove}
                onCarSelected={onCardClick}
                gridWidth={gridWidth}
              />
            </>
          }
          {approved.length > 0 &&
            < >
              <Heading textAlign="center">Approvati</Heading>
              <ListingGrid
                listings={approved}
                onCarSelected={onCardClick}
                gridWidth={gridWidth}
              />
            </>
          }
        </div>
      </Flex>
      <UserProfile isOpen={modalProps.isOpen} onOpen={modalProps.onOpen} onClose={modalProps.onClose} />
    </PageContainer>
  );
}
